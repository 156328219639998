// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
// Assets
import React, { useState } from 'react';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { AiTwotoneEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

// Antd imports
import { DotChartOutlined } from '@ant-design/icons';
import { Skeleton, Typography, Space, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const { Title } = Typography;
export default function NFT(props) {
  const { image, name, name2, name3, status, AllData } = props;

  const navigate = useNavigate();

  const [like, setLike] = useState(false);
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorBid = useColorModeValue('brand.500', 'white');
  return (
    <Card
      style={{
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Skeleton.Image
        active
        style={{
          height: '200px',
          width: '100%',
          marginBottom: '16px',
          borderRadius: '16px',
        }}
      />
      <Skeleton loading active paragraph={{ rows: 2 }}>
        <Space direction="vertical">
          <Title level={4}>
            Control Your Shutter Remotely with WiFi Connectivity
          </Title>
          <Text type="secondary">Device Status</Text>
          <Tag color="red">Status: Offline</Tag>
        </Space>
      </Skeleton>
    </Card>
  );
}
