import React, { useState, useEffect } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { Button as AntdButton, ConfigProvider, Flex, Select } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import batch from '../../../api/methods/batch';
import Variant from '../../../api/methods/variant';
import product from '../../../api/methods/product';
import ViewComponent from './components/View';
import FormComponent from './components/Form';
import ListComponent from './components/List';
import buttonStyle from '../../../components/button/linearGradient';

export default function CategoryList() {
  // State variables
  const [dataCategory, setDataCategory] = useState([]);
  const [loading, setLoading] = useState({ category: true, variant: false });
  const [data, setData] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [formType, setFormType] = useState('create');
  const [selectedCategory, setSelectedCategory] = useState();

  const toast = useToast();
  const { styles } = buttonStyle();

  // Fetch all batch data
  const fetchCategoryData = () => {
    setLoading((prev) => ({ ...prev, category: true }));
    batch
      .list()
      .then((response) => {
        const categoryList = response.data.map((item) => ({
          label: `${item.batch_name} - ${item.batch_number}`,
          value: item.id,
        }));
        setDataCategory(categoryList);
      })
      .finally(() => setLoading((prev) => ({ ...prev, category: false })));
  };

  // Fetch variant data based on selected category
  const fetchData = (id) => {
    const categoryId = id || selectedCategory;
    setSelectedCategory(categoryId);
    setLoading((prev) => ({ ...prev, variant: true }));

    product
      .listByBatch(categoryId)
      .then((response) => {
        const responseData = response.data.map((item) => ({
          key: item?.id,
          id: item?.id,
          serial_number: item?.serial_number,
          MFG_date: item?.MFG_date,
          alias_name: item?.alias_name,
          token: item?.token,
          installation_date: item?.installation_date,
          warranty_end_date: item?.warranty_end_date,
          MAC_address: item?.MAC_address,
          Chip_id: item?.Chip_id,
          SKU_numbers: item?.SKU_numbers,
          remarks: item?.remarks,
          is_sold: item?.is_sold,
          is_working: item?.is_working,
          is_connected: item?.is_connected,
          Parameters: item?.Parameters,
          batchID: item?.batch?.id,
          batchNumber: item?.batch?.batch_number,
          variantName: item?.batch?.variant?.name,
          batch: item?.batch,
        }));
        setData(responseData);
      })
      .catch((error) => {
        console.error(error);
        setData([]);
        toast({
          title: error.response?.data?.message || 'Error fetching data',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setLoading((prev) => ({ ...prev, variant: false })));
  };

  // Fetch category data on component mount
  useEffect(() => {
    fetchCategoryData();
  }, []);

  // Handler for adding a new variant
  const handleAddCategory = () => {
    setFormType('create');
    setOpenForm(true);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex className="my-2 justify-between">
        <Select
          placeholder="Select Batch"
          style={{ width: 300 }}
          loading={loading.category}
          value={selectedCategory}
          disabled={loading.category}
          options={dataCategory ? dataCategory : []}
          onChange={(value) => {
            fetchData(value);
            setSelectedCategory(value);
          }}
          showSearch
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA.label || '')
              .toLowerCase()
              .localeCompare((optionB.label || '').toLowerCase())
          }
        />
        <ConfigProvider button={{ className: styles.linearGradientButton }}>
          <AntdButton
            onClick={handleAddCategory}
            type="primary"
            icon={<UserAddOutlined />}
            size="large"
            className="float-end w-48 text-lg font-medium"
          >
            Add Product
          </AntdButton>
        </ConfigProvider>
      </Flex>

      <ListComponent
        data={data}
        fetchData={fetchData}
        setOpenForm={setOpenForm}
        setSelectedData={setSelectedData}
        setFormType={setFormType}
        loading={loading.variant}
        setOpenView={setOpenView}
      />

      <FormComponent
        openForm={openForm}
        setOpenForm={setOpenForm}
        fetchData={fetchData}
        selectedData={selectedData}
        formType={formType}
        dataCategory={dataCategory}
      />

      <ViewComponent
        openView={openView}
        setOpenView={setOpenView}
        selectedData={selectedData}
      />
    </Box>
  );
}
