// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Icon,
  Text,
  Menu,
  MenuButton,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { IoArrowBack } from 'react-icons/io5';

export default function AdminNavbar(props) {
  const navigate = useNavigate();

  const navbarIcon = useColorModeValue('gray.600', 'white');

  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const [showGoBack, setShowGoBack] = useState(true);

  useEffect(() => {
    let hash = location.pathname;
    console.log(hash);
    const cleanPath = hash ? hash.split('?')[0] : null;
    console.log(cleanPath);
    if (cleanPath === '/admin/default') {
      setShowGoBack(false);
    } else {
      setShowGoBack(true);
    }
  }, [location.pathname]);

  const { secondary, message, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue('navy.700', 'white');
  let secondaryText = useColorModeValue('gray.700', 'white');
  let navbarPosition = 'fixed';
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue(
    'rgba(244, 247, 254, 0.2)',
    'rgba(11,20,55,0.5)',
  );
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const shadow = useColorModeValue(
    '7px 8.5px 20px 2px rgba(112, 144, 176, 0.18)',
    '7px 8.5px 20px 2px rgba(112, 144, 176, 0.06)',
  );
  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      // bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={'flex'}
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      // right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
      // px={{
      //   sm: paddingX,
      //   md: '10px',
      // }}

      px={'20px'}
      ps={{
        base: '20px',
        md: '30px',
      }}
      pt="8px"
      top={{ base: '12px', md: '16px', lg: '20px', xl: '20px' }}
      w={'100%'}
      // style={{ paddingLeft: '5%' }}
    >
      <Flex
        w="100%"
        flexDirection={'row'}
        // pl="40px"
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Box mb={{ sm: '8px', md: '0px' }}>
          {showGoBack ? (
            <Menu>
              <MenuButton
                w="50px"
                h="50px"
                // boxShadow={shadow}
                p="10px"
                borderRadius="35px"
                boxShadow={shadow}
                marginRight={'1rem'}
                // background={'white'}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <Icon
                  // mt="6px"
                  as={IoArrowBack}
                  color={navbarIcon}
                  w="28px"
                  h="28px"
                  me="10px"
                />
              </MenuButton>
            </Menu>
          ) : (
            <>
              {' '}
              <Breadcrumb>
                <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
                  <BreadcrumbLink href="#" color={secondaryText}>
                    Pages
                  </BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
                  <BreadcrumbLink href="#" color={secondaryText}>
                    {brandText}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              {/* Here we create navbar brand, based on route name */}
              <Link
                color={mainText}
                href="#"
                bg="inherit"
                borderRadius="inherit"
                fontWeight="bold"
                fontSize="34px"
                _hover={{ color: { mainText } }}
                _active={{
                  bg: 'inherit',
                  transform: 'none',
                  borderColor: 'transparent',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                Smart Shutter
              </Link>
            </>
          )}
        </Box>
        <Flex ms="auto">
          <Box>
            <AdminNavbarLinks
              onOpen={props.onOpen}
              logoText={props.logoText}
              secondary={props.secondary}
              fixed={props.fixed}
              scrolled={scrolled}
            />
          </Box>
        </Flex>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
