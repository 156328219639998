import React, { useState, useEffect } from 'react';
import { Modal, Descriptions, Image, Tag, Typography } from 'antd';
import moment from 'moment';
import { Base_URL } from 'services/DataConfig';

export default function ViewComponent({ openView, setOpenView, selectedData }) {
  const [details, setDetails] = useState([]);
  const { Text } = Typography;

  // Helper function to filter data
  const filterData = (data) =>
    data ? (data === 'Invalid date' ? '-' : data) : '-';

  // Prepare row details
  useEffect(() => {
    if (!selectedData) return;

    const rowDetails = [
      {
        key: '1',
        label: 'Serial Number',
        span: 2,
        children: filterData(selectedData.serial_number),
      },
      {
        key: '2',
        label: 'Manafacture Date',
        span: 2,
        children: filterData(
          moment(selectedData.MFG_date).format('MMMM Do YYYY'),
        ),
      },
      {
        key: '3',
        label: 'Chip ID',
        span: 2,
        children: filterData(selectedData.Chip_id),
      },
      {
        key: '4',
        label: 'MAC Address',
        span: 2,
        children: filterData(selectedData.MAC_address),
      },
      {
        key: '5',
        label: 'Token',
        span: 4,
        children: filterData(selectedData.token),
      },
      {
        key: '6',
        label: 'Serial Numbers',
        span: 2,
        children: (
          <>
            {selectedData.SKU_numbers &&
              selectedData.SKU_numbers.map((field, index) => (
                <div key={index}>
                  <Text strong>{field.key}:</Text> {field.value}
                </div>
              ))}
          </>
        ),
      },
      {
        key: '7',
        label: 'Parameters',
        span: 2,
        children: (
          <>
            {selectedData.Parameters &&
              selectedData.Parameters.map((field, index) => (
                <div key={index}>
                  <Text strong>{field.key}:</Text> {field.value}
                </div>
              ))}
          </>
        ),
      },
      {
        key: '8',
        label: 'Installation Date',
        span: 2,
        children: filterData(
          moment(selectedData.installation_date).format('MMMM Do YYYY'),
        ),
      },
      {
        key: '9',
        label: 'Warranty Expiry Date',
        span: 2,
        children: filterData(
          moment(selectedData.warranty_end_date).format('MMMM Do YYYY'),
        ),
      },
      {
        key: '11',
        label: 'Variant Name',
        span: 4,
        children: filterData(selectedData.variantName),
      },
      {
        key: '10',
        label: 'Batch Number',
        span: 2,
        children: filterData(selectedData.batchNumber),
      },

      {
        key: '12',
        label: 'Product Status',
        span: 2,
        children: selectedData.is_sold ? 'Sold' : 'Available',
      },
      {
        key: '13',
        label: 'Working Status',
        span: 2,
        children: selectedData.is_sold ? 'Working' : 'Not Working',
      },
      {
        key: '14',
        label: 'Connection Status',
        span: 2,
        children: selectedData.is_connected ? 'Connected' : 'Disconnected',
      },
      {
        key: '15',
        label: 'Remarks',
        span: 4,
        children: filterData(selectedData.remarks),
      },
    ];

    setDetails(rowDetails);
  }, [selectedData]);

  return (
    <Modal
      open={openView}
      footer={null}
      onCancel={() => setOpenView(false)}
      width="800px"
    >
      <Descriptions
        bordered
        size="medium"
        title="Product Details"
        layout="vertical"
        column={4}
      >
        {details.map(({ key, label, span, children }) => (
          <Descriptions.Item key={key} label={label} span={span}>
            {children}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Modal>
  );
}
