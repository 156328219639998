import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Button } from '@chakra-ui/react';
import { Label } from '../../src/AceternityComponents/ui/label.tsx';
import { Input } from '../../src/AceternityComponents/ui/input.tsx';
import { cn } from '../../lib/utils.ts';
import { IconBrandGithub, IconBrandGoogle } from '@tabler/icons-react';

import authApis from '../../api/methods/auth.jsx';

import {
  setPCRStatus,
  storeRefreshToken,
  storeToken,
  storeUserId,
  storeInstituteId,
  storeEmail,
} from '../../services/localServices.jsx';

export default function SignupForm() {
  const navigate = useNavigate();

  const toast = useToast();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === '') {
      toast({
        title: `Please enter email`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (password === '') {
      toast({
        title: `Please enter password`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setButtonLoading(true);

    //    email: "smartshutter@yopmail.com",
    //    password:"MyAccess@Dev"

    authApis
      .login({
        email: email,
        password: password,
      })
      .then((res) => {
        console.log(res);
        // let PasswordFetch = values["password"];
        // let PasswordSplit = PasswordFetch.split("_");
        // if (`${PasswordSplit[0]}` === "STP") {
        //   setPCRStatus("true");
        // } else {
        //   setPCRStatus("false");
        // }
        storeEmail(email);

        storeToken(res.data.token.access);
        storeRefreshToken(res.data.token.refresh);
        toast({
          title: 'Welcome to Your Dashboard',
          description: 'Your gateway to seamless shutter control starts here.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/admin');
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response.data);
        if (
          e.response?.data?.error ===
          'Email not verified. OTP has been resent. Please check your email.'
        ) {
          storeEmail(email);
          navigate('/auth/sign-up#VerifyOTP');
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };
  return (
    <div className="max-w-md w-full z-20 mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
      <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
        Welcome to Effortless Shutter Control
      </h2>
      <p className="text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300">
        Sign in to manage your shutters with just a click.
      </p>

      <form className="my-8" onSubmit={handleSubmit}>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="email">Email Address</Label>
          <Input
            required={true}
            id="email"
            value={email}
            placeholder="you@example.com"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="password">Password</Label>
          <Input
            required={true}
            id="password"
            value={password}
            placeholder="••••••••"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </LabelInputContainer>
        <div className="h-10 flex justify-between items-center float-end cursor-pointer">
          <p
            className="text-neutral-800 text-[14px] max-w-sm mt-0 mb-2 font-medium  ease-in duration-300 dark:text-neutral-300 hover:underline"
            onClick={(e) => {
              navigate('#ForgotPassword');
            }}
          >
            Forgot Password ?
          </p>
        </div>

        {/* <button
          className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
          type="submit"
          onClick={() => {}}
        >
          Sign in &rarr;
          <BottomGradient />
        </button> */}

        <Button
          type="submit"
          // variant="unstyled" // Removes default styling
          width="full"
          height="10"
          fontWeight="medium"
          color="white"
          rounded="md"
          bg="gray.800"
          isLoading={buttonLoading}
          sx={{
            _hover: {
              backgroundColor: 'black',
            }, // Removes the hover effect
          }}
        >
          Sign in &rarr;
          <BottomGradient />
        </Button>
        <div className="mt-4 flex items-center justify-center  w-full">
          <p className="text-neutral-800 text-[14px] max-w-sm mt-0 mb-0  font-medium  ease-in duration-300 dark:text-neutral-300">
            Don't have an account?{' '}
            <span
              className="cursor-pointer font-bold text-black hover:underline"
              onClick={(e) => {
                navigate('/auth/sign-up');
              }}
            >
              Sign Up
            </span>
          </p>
        </div>
        <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent mt-6 mb-4 h-[1px] w-full" />
        <div className="flex ">
          <button
            className=" relative group/btn flex space-x-2 mx-2 items-center justify-start px-4 w-1/2 text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]"
            // type="submit"
            onClick={(e) => {
              e.preventDefault();
              toast({
                title: `Authentication with Google is not available yet.`,
                status: 'info',
                duration: 3000,
                isClosable: true,
              });
            }}
          >
            <IconBrandGoogle className="h-4 w-4 text-neutral-800 dark:text-neutral-300" />
            <span className="text-neutral-700 dark:text-neutral-300 text-sm">
              Google
            </span>
            <BottomGradient />
          </button>
          <button
            className=" relative group/btn flex space-x-2 mx-2 items-center justify-start px-4 w-1/2 text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]"
            // type="submit"
            onClick={(e) => {
              e.preventDefault();
              toast({
                title: `Authentication with Facebook is not available yet.`,
                status: 'info',
                duration: 3000,
                isClosable: true,
              });
            }}
          >
            <IconBrandGithub className="h-4 w-4 text-neutral-800 dark:text-neutral-300" />
            <span className="text-neutral-700 dark:text-neutral-300 text-sm">
              Facebook
            </span>
            <BottomGradient />
          </button>
        </div>
      </form>
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn('flex flex-col space-y-2 w-full', className)}>
      {children}
    </div>
  );
};
