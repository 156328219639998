import React, { useState } from 'react';
import {
  Space,
  Table,
  Typography,
  Image,
  Button,
  Popconfirm,
  Tag,
  Badge,
  Input,
  Modal,
} from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useToast } from '@chakra-ui/react';

import category from '../../../../api/methods/category';
import { Base_URL } from 'services/DataConfig';

export default function ListComponent({
  data,
  fetchData,
  setOpenForm,
  setSelectedData,
  setFormType,
  loading,
  setOpenView,
}) {
  // State variables
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedDeleteData, setSelectedDeleteData] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedDelete, setSelectedDelete] = useState('');

  const { Paragraph } = Typography;
  const toast = useToast();

  // Column definitions for the table
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (text, record) => (
        <span>
          <Badge status={record.isActive ? 'success' : 'default'} /> {text}
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (
        <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
          {text || '-'}
        </Paragraph>
      ),
    },
    {
      title: 'Features',
      key: 'features',
      dataIndex: 'features',
      width: 250,
      render: (_, record) => (
        <>
          {typeof record?.features === 'object'
            ? Object.entries(record?.features).map((tag) => (
                <Tag color="blue" key={tag[0]}>
                  {tag[1]}
                </Tag>
              ))
            : record.features}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Space size="middle" key={record.id}>
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              setSelectedData(record);
              setOpenView(true);
            }}
          />
          <Popconfirm
            title="Edit the Category"
            description="Are you sure to edit this Category?"
            onConfirm={() => {
              setSelectedData(record);
              setFormType('edit');
              setOpenForm(true);
            }}
            onCancel={() => console.log('Edit cancelled')}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<EditOutlined />} />
          </Popconfirm>
          <Popconfirm
            title="Delete the Category"
            description="Are you sure to delete this Category?"
            onConfirm={() => {
              setSelectedDelete(record);
              setIsModalVisible(true);
            }}
            onCancel={() => console.log('Delete cancelled')}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              icon={<DeleteOutlined />}
              loading={selectedDeleteData === record.id ? deleteLoading : false}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // Function to handle delete action
  const handleDelete = (id) => {
    setDeleteLoading(true);
    setSelectedDeleteData(id);
    category
      .delete(id)
      .then(() => fetchData())
      .finally(() => setDeleteLoading(false));
  };

  // Modal handlers
  const handleOk = () => {
    if (inputValue === selectedDelete.name) {
      handleDelete(selectedDelete.id);
      setInputValue('');
      setIsModalVisible(false);
    } else {
      toast({
        title: 'The name does not match!',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setInputValue('');
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data}
        bordered
        pagination={false}
        scroll={isSmallScreen ? { y: 800 } : undefined}
      />
      <Modal
        title="Confirm Delete"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p className="mb-2">
          <strong>Warning:</strong> Once deleted, this item cannot be retrieved.
        </p>
        <p className="mb-2">
          To confirm deletion, please type the name:{' '}
          <strong>{selectedDelete.name}</strong>
        </p>
        <Input
          placeholder="Enter Name"
          value={inputValue}
          status={inputValue === selectedDelete.name ? 'success' : 'error'}
          onChange={handleInputChange}
        />
      </Modal>
    </>
  );
}
