import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Button as AntdButton, ConfigProvider } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

// API methods and custom components
import category from '../../../api/methods/category';
import ViewComponent from './components/View';
import FormComponent from './components/Form';
import ListComponent from './components/List';
import buttonStyle from '../../../components/button/linearGradient';

export default function CategoryList() {
  // State variables
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [formType, setFormType] = useState('create');

  // Custom button styles
  const { styles } = buttonStyle();

  // Function to fetch category data
  const fetchData = () => {
    setLoading(true);
    category
      .list()
      .then((response) => {
        console.log('Category List Response:', response.data);
        const responseData = response.data.map((data) => ({
          key: data?.id,
          id: data?.id,
          name: data?.name,
          description: data?.description,
          features: data?.features ? JSON.parse(data?.features) : data.features,
          created_date: data?.created_date,
          isActive: data?.is_active,
          image1: data?.image1,
          image2: data?.image2,
          image3: data?.image3,
          varients: data?.variant,
        }));
        setData(responseData);
      })
      .finally(() => setLoading(false));
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Handler for adding a new category
  const handleAddCategory = () => {
    setFormType('create');
    setOpenForm(true);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <ConfigProvider button={{ className: styles.linearGradientButton }}>
        <AntdButton
          onClick={handleAddCategory}
          type="primary"
          icon={<UserAddOutlined />}
          size="large"
          className="my-2 float-end w-48 text-lg font-medium"
        >
          Add Category
        </AntdButton>
      </ConfigProvider>

      <ListComponent
        data={data}
        fetchData={fetchData}
        setOpenForm={setOpenForm}
        setSelectedData={setSelectedData}
        setFormType={setFormType}
        loading={loading}
        setOpenView={setOpenView}
      />

      <FormComponent
        openForm={openForm}
        setOpenForm={setOpenForm}
        fetchData={fetchData}
        selectedData={selectedData}
        formType={formType}
      />

      <ViewComponent
        openView={openView}
        setOpenView={setOpenView}
        selectedData={selectedData}
      />
    </Box>
  );
}
