import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Button,
  Input,
  Upload,
  Select,
  Switch,
  Typography,
  Flex,
  DatePicker,
} from 'antd';
import { useToast } from '@chakra-ui/react';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import product from '../../../../api/methods/product';
import { Base_URL } from 'services/DataConfig';
import dayjs from 'dayjs';

export default function FormComponent({
  openForm,
  setOpenForm,
  fetchData,
  selectedData,
  formType,
  dataCategory,
}) {
  // Default specification fields
  const defaultFields = [
    { key: 'Motor Number', value: '' },
    { key: 'Gearbox Number', value: '' },
    { key: 'BMS Number', value: '' },
  ];

  // State variables
  const [form] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [fields, setFields] = useState(defaultFields);

  const toast = useToast();
  const { Text } = Typography;

  // Load selected data when formType is 'edit'
  useEffect(() => {
    if (formType === 'edit') {
      initializeEditForm();
    } else if (formType === 'create') {
      resetForm();
    }
  }, [formType, selectedData]);

  // Initialize form for editing
  const initializeEditForm = () => {
    let MFG_date = dayjs(selectedData?.MFG_date, 'YYYY-MM-DD');
    console.log(MFG_date);
    form.setFieldsValue({
      ...selectedData,
      MFG_date: MFG_date,
      batch: selectedData.batchID,
    });
    setFields(selectedData.SKU_numbers || []);
  };

  // Reset form for creation
  const resetForm = () => {
    form.resetFields();
    setFields([...defaultFields]);
  };

  // Handle form submission
  const onFinish = async (values) => {
    if (validateForm(values)) {
      submitForm(values);
    }
  };

  // Validate form before submission
  const validateForm = (values) => {
    // if (featureList.length === 0) {
    //   showToast('Please add at least one Serial Number!', 'info');
    //   return false;
    // }
    return true;
  };

  // Show toast messages
  const showToast = (message, status) => {
    toast({
      title: message,
      status,
      duration: 3000,
      isClosable: true,
    });
  };

  // Submit form data
  const submitForm = async (values) => {
    setFormSubmitting(true);
    const date = new Date(values.MFG_date);
    const offsetInMinutes = date.getTimezoneOffset() + 660; // 330 minutes for +5:30
    date.setMinutes(date.getMinutes() + offsetInMinutes);
    console.log(date);
    console.log(date.toISOString().split('T'));
    values.MFG_date = date.toISOString().split('T')[0];

    const formData = new FormData();
    formData.append('batch', values.batch);
    formData.append('Chip_id', values.Chip_id);
    formData.append('MAC_address', values.MAC_address);
    formData.append('MFG_date', values.MFG_date);
    formData.append('remarks', values?.remarks);
    formData.append('SKU_numbers', JSON.stringify(fields));

    const action = formType === 'edit' ? product.update : product.create;
    const successMessage =
      formType === 'edit'
        ? 'Product Updated Successfully!'
        : 'Product Created Successfully!';

    action(formData, selectedData?.id)
      .then(() => {
        showToast(successMessage, 'success');
        resetForm();
        setOpenForm(false);
        fetchData(values.batch);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => setFormSubmitting(false));
  };

  // Handle form submission failure
  const onFinishFailed = (errorInfo) => {
    showToast(
      errorInfo.errorFields[0]?.errors[0] || 'Form submission failed!',
      'info',
    );
  };

  // Handle input change for specifications
  const handleInputChange = (index, name, value) => {
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    setFields(updatedFields);
  };

  // Add new specification field
  const addField = () => {
    setFields([...fields, { key: '', value: '' }]);
  };

  // Remove specification field
  const removeField = (index) => {
    setFields((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Modal
      title={`${formType === 'edit' ? 'Update' : 'Create'} Variant`}
      open={openForm}
      footer={null}
      onCancel={() => {
        resetForm();
        setOpenForm(false);
      }}
      width="600px"
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        size="large"
      >
        <Form.Item
          name="batch"
          label="Batch"
          rules={[{ required: true, message: 'Please select the Batch!' }]}
        >
          <Select
            placeholder="Select Batch"
            options={dataCategory}
            onChange={(value) => console.log(value)}
            showSearch
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA.label || '')
                .toLowerCase()
                .localeCompare((optionB.label || '').toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="MFG_date"
          label="Manafacture Date"
          rules={[
            { required: true, message: 'Please select the Manafacture Date!' },
          ]}
        >
          <DatePicker
            format={'YYYY-MM-DD'}
            onChange={(e) => console.log(e)}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="Chip_id"
          label="Chip ID"
          rules={[{ required: true, message: 'Please enter the Chip ID!' }]}
        >
          <Input placeholder="Enter Chip ID" />
        </Form.Item>
        <Form.Item
          name="MAC_address"
          label="MAC Address"
          rules={[{ required: true, message: 'Please enter the MAC Address!' }]}
        >
          <Input placeholder="Enter MAC Address" />
        </Form.Item>
        <Flex justify="space-between" style={{ marginBottom: '20px' }}>
          <Text>Serial Numbers</Text>
          <Button type="primary" size="small" onClick={addField}>
            Add Field
          </Button>
        </Flex>
        {fields.map((field, index) => (
          <Form.Item key={index}>
            <Flex justify="space-between">
              <Input
                placeholder="Key"
                value={field.key}
                onChange={(e) =>
                  handleInputChange(index, 'key', e.target.value)
                }
                style={{ width: '44%' }}
                readOnly={index < defaultFields.length}
                required
              />
              <Input
                placeholder="Value"
                value={field.value}
                onChange={(e) =>
                  handleInputChange(index, 'value', e.target.value)
                }
                style={{ width: '44%' }}
                required
              />
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => removeField(index)}
                disabled={index < defaultFields.length}
              />
            </Flex>
          </Form.Item>
        ))}
        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea placeholder="Enter Remarks" rows={3} />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={formSubmitting}
            style={{ width: '200px' }}
          >
            {formType === 'edit' ? 'Update Product' : 'Create Product'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
