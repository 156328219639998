import React, { useState, useEffect } from 'react';
import { Modal, Descriptions, Image, Tag } from 'antd';
import moment from 'moment';
import { Base_URL } from 'services/DataConfig';

export default function ViewComponent({ openView, setOpenView, selectedData }) {
  const [details, setDetails] = useState([]);

  // Helper function to filter data
  const filterData = (data) => (data ? data : '-');

  // Effect to prepare row details when selectedData changes
  useEffect(() => {
    const rowDetails = [
      {
        key: '1',
        label: 'Batch Name',
        span: 2,
        children: filterData(selectedData?.name),
      },
      {
        key: '2',
        label: 'Batch ID',
        span: 2,
        children: filterData(selectedData?.batch_number),
      },
      {
        key: '3',
        label: 'Variant ',
        span: 2,
        children: filterData(selectedData?.varients?.name),
      },
      {
        key: '4',
        label: 'Start Date',
        span: 1,
        children: filterData(
          moment(selectedData?.batch_start_date).format('MMMM Do YYYY'),
        ),
      },
      {
        key: '5',
        label: 'End Date',
        span: 1,
        children: filterData(
          moment(selectedData?.batch_end_date).format('MMMM Do YYYY'),
        ),
      },
      {
        key: '6',
        label: 'Remarks',
        span: 2,
        children: filterData(selectedData?.remarks),
      },
    ];
    setDetails(rowDetails);
  }, [selectedData]);

  return (
    <Modal
      open={openView}
      footer={null}
      onCancel={() => setOpenView(false)}
      width="600px"
    >
      <Descriptions
        bordered
        size="medium"
        title="Batch Details"
        layout="vertical"
        column={2}
      >
        {details.map((item) => (
          <Descriptions.Item key={item.key} label={item.label} span={item.span}>
            {item.children}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Modal>
  );
}
