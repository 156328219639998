// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
// Assets
import React, { useState } from 'react';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { AiTwotoneEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { storeDeviceData, readDeviceData } from 'services/localServices';
export default function NFT(props) {
  const { image, name, name2, name3, status, AllData } = props;

  const navigate = useNavigate();

  const [like, setLike] = useState(false);
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorBid = useColorModeValue('brand.500', 'white');
  return (
    <Card p="20px">
      <Flex
        direction={{ base: 'column' }}
        justify="center"
        onClick={() => {
          storeDeviceData({
            Chip_id: AllData.Chip_id,
            serial_number: AllData.serial_number,
            token: AllData.token,
            MAC_address: AllData.MAC_address,
            data: AllData,
          });
          navigate('/admin/control-device');
        }}
        cursor={'pointer'}
      >
        <Box mb={{ base: '20px', '2xl': '20px' }} position="relative">
          <Image
            src={image}
            w={{ base: '100%', '3xl': '100%' }}
            h={{ base: '100%', '3xl': '100%' }}
            borderRadius="20px"
          />
          <Button
            position="absolute"
            bg="white"
            _hover={{ bg: 'whiteAlpha.900' }}
            _active={{ bg: 'white' }}
            _focus={{ bg: 'white' }}
            p="0px !important"
            top="14px"
            right="14px"
            borderRadius="50%"
            minW="36px"
            h="36px"
            style={{ zIndex: '100' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              console.log('Hello33');
            }}
          >
            <Icon
              transition="0.2s linear"
              w="20px"
              h="20px"
              as={AiTwotoneEdit}
              color="brand.500"
            />
          </Button>
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex justify="space-between" direction={'column'} mb="auto">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize={{
                  base: 'xl',
                  md: 'lg',
                  lg: 'lg',
                  xl: 'lg',
                  '2xl': 'md',
                  '3xl': 'lg',
                }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {name}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize={{
                  base: 'sm',
                }}
                fontWeight="400"
                me="14px"
              >
                {name2 ? name2 : ''}
              </Text>
              <Flex
                align="center "
                justify="space-between"
                direction={'row'}
                mt="8px"
              >
                <Text
                  color="secondaryGray.600"
                  fontSize={{
                    base: 'sm',
                  }}
                  fontWeight="400"
                >
                  {name3}
                </Text>
                <Text
                  fontWeight="700"
                  fontSize="sm"
                  color={
                    status === 'Offline' ? 'secondaryGray.600' : textColorBid
                  }
                >
                  Status: {status}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
