export const isLoggedIn = () => {
  return localStorage.getItem('access') ? true : false;
};

export const setLoggedIn = (value) => {
  localStorage.setItem('access', value);
};

export const handleLogout = () => {
  localStorage.clear();
};

export const storeDeviceData = (data) => {
  localStorage.setItem('DeviceData', JSON.stringify(data));
};

export const readDeviceData = () => {
  return JSON.parse(localStorage.getItem('DeviceData'));
};

export const storeEmail = (data) => {
  localStorage.setItem('email', data);
};

export const readEmail = () => {
  return localStorage.getItem('email');
};

export const storeToken = (token) => {
  localStorage.setItem('access', token);
};

export const readToken = () => {
  return localStorage.getItem('access');
};

export const storeRefreshToken = (token) => {
  localStorage.setItem('refresh', token);
};

export const readRefreshToken = () => {
  return localStorage.getItem('refresh');
};

export const storeUserId = (token) => {
  localStorage.setItem('id', token);
};
export const readUserId = () => {
  return localStorage.getItem('id');
};

export const setPCRStatus = (data) => {
  localStorage.setItem('PCR', data);
};
export const getPCRStatus = () => {
  return localStorage.getItem('PCR') === 'true' ? true : false;
};

export const isDarkTheme = () => {
  return localStorage.getItem('chakra-ui-color-mode');
};
export const setIsDarkTheme = (value) => {
  localStorage.setItem('chakra-ui-color-mode', value);
};
