import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import Auth from 'views/auth/index.jsx';
import SignUp from 'views/auth/signUp.jsx';

// Admin Page Imports
import AdminAccounts from 'views/admin/AdminAccounts';
import CategoryList from 'views/admin/CategoryList';
import VariantList from 'views/admin/VariantList';
import BatchList from 'views/admin/BatchList';
import ProductList from 'views/admin/ProductList';

import HomePage from 'views/Pages/HomePage';
import VariantsPage from 'views/Pages/VariantsPage';
import ControlPage from 'views/Pages/ControlPage';

const routes = [
  {
    name: 'Home Page',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <HomePage />,
    visible: true,
  },
  {
    name: 'Control Device',
    layout: '/admin',
    path: '/control-device',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <ControlPage />,
    visible: true,
  },
  {
    name: 'Variants',
    layout: '/admin',
    path: '/variants-list',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <VariantsPage />,
    visible: true,
  },

  {
    name: 'Admin Accounts',
    layout: '/admin',
    path: '/admin-accounts',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <AdminAccounts />,
    visible: true,
  },
  {
    name: 'Category',
    layout: '/admin',
    path: '/category-list',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <CategoryList />,
    visible: true,
  },
  {
    name: 'Variant',
    layout: '/admin',
    path: '/variant-list',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <VariantList />,
    visible: true,
  },
  {
    name: 'Batch',
    layout: '/admin',
    path: '/batch-list',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <BatchList />,
    visible: true,
  },
  {
    name: 'Product',
    layout: '/admin',
    path: '/product-list',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <ProductList />,
    visible: true,
  },
  // {
  //   name: 'Add Device',
  //   layout: '/user',
  //   path: '/add-device',
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: <ProductList />,
  //   visible: true,
  // },
  {
    name: 'NFT Marketplace',
    layout: '/admin',
    path: '/dashboard',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <MainDashboard />,
    secondary: true,
    visible: true,
  },
  {
    name: 'NFT Marketplace',
    layout: '/admin',
    path: '/nft-marketplace',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: <NFTMarketplace />,
    secondary: true,
    visible: true,
  },
  {
    name: 'Data Tables',
    layout: '/admin',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/data-tables',
    component: <DataTables />,
    visible: true,
  },
  {
    name: 'Profile',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
    visible: true,
  },
  // Authentication Paths
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <Auth />,
    // visible: true,
  },
  {
    name: 'Sign Up',
    layout: '/auth',
    path: '/sign-up',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignUp />,
    // visible: true,
  },
  {
    name: 'RTL Admin',
    layout: '/rtl',
    path: '/rtl-default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <RTL />,
  },
];

export default routes;
