// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  useToast,
} from '@chakra-ui/react';
import { Modal, Form, DatePicker, Upload, Select, Switch } from 'antd';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';

import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes';
import { handleLogout } from 'services/localServices';

import { Label } from '../../src/AceternityComponents/ui/label.tsx';
import { Input } from '../../src/AceternityComponents/ui/input.tsx';
import { cn } from '../../lib/utils.ts';

import authApis from '../../api/methods/auth.jsx';
import userProduct from '../../api/methods/userProduct.jsx';
import ValidateOTP from '../../NewComponents/User/ValidateOTP.jsx';

export default function HeaderLinks(props) {
  const navigate = useNavigate();
  const toast = useToast();
  const [form] = Form.useForm();

  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.600', 'white');
  const navbarIcon2 = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '7px 8.5px 20px 2px rgba(112, 144, 176, 0.18)',
    '7px 8.5px 20px 2px rgba(112, 144, 176, 0.06)',
  );
  // const shadow = useColorModeValue(
  //   '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
  //   '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  // );
  const location = useLocation();
  //

  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

  const [serialNumber, setSerialNumber] = useState('');
  const [token, setToken] = useState('');
  const [ValidationOtp, setValidationOtp] = useState('');

  const [UIDdata, setUIDdata] = useState('');
  const [Tokendata, setTokdendata] = useState('');
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');

  const [buttonLoading, setButtonLoading] = useState(false);

  const [showAddDevice, setShowAddDevice] = useState(true);

  useEffect(() => {
    let hash = location.pathname;
    console.log(hash);
    const cleanPath = hash ? hash.split('?')[0] : null;
    console.log(cleanPath);
    if (cleanPath === '/admin/variants-list') {
      setShowAddDevice(false);
    } else {
      setShowAddDevice(true);
    }
  }, [location.pathname]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (serialNumber === '') {
      toast({
        title: `Please enter the serial number`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (token === '') {
      toast({
        title: `Please enter the token`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setButtonLoading(true);
    userProduct
      .add({
        serial_number: serialNumber,
        token: token,
      })
      .then((res) => {
        console.log(res);
        toast({
          title: 'OTP Sent',
          description: 'An OTP has been sent to your registered email.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setOpenForm(false);
        setOpenValidateOtpForm(true);
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: 'Failed to Add Device',
          description:
            'Please check your Serial Number and Token and try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const [openForm, setOpenForm] = useState(false);
  const [openValidateOtpForm, setOpenValidateOtpForm] = useState(false);
  const resetAndCloseForm = () => {
    form.resetFields();
    setOpenForm(false);
    // setSelectedData({});
  };

  useEffect(() => {
    // FetchUserProducts();
  }, []);

  function FetchUserProducts() {
    // userProduct.list().then((data) => {
    //   console.log(data);
    // });
    window.reload();
  }
  return (
    <>
      <Flex
        // w={{ sm: '0%', md: 'auto' }}
        alignItems="center"
        flexDirection="row"
        // bg={menuBg}
        flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
        p="10px"
        borderRadius="35px"
        // boxShadow={shadow}
      >
        {showAddDevice && (
          <Menu>
            <MenuButton
              w="50px"
              h="50px"
              // boxShadow={shadow}
              p="10px"
              borderRadius="35px"
              boxShadow={shadow}
              marginRight={'1rem'}
              // background={'white'}
            >
              <Icon
                // mt="6px"
                as={IoMdAdd}
                color={navbarIcon}
                w="28px"
                h="28px"
                me="10px"
              />
            </MenuButton>
            <MenuList
              boxShadow={shadow}
              p="0px"
              mt="10px"
              borderRadius="20px"
              // bg={menuBg}
              border="none"
            >
              <Flex flexDirection="column" p="10px">
                <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  borderRadius="8px"
                  px="14px"
                  onClick={() => {
                    setOpenForm(true);
                    setSerialNumber('');
                    setToken('');
                  }}
                >
                  <Text fontSize="sm">Add Device</Text>
                </MenuItem>
                <MenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  borderRadius="8px"
                  px="14px"
                >
                  <Text
                    fontSize="sm"
                    onClick={() => {
                      navigate('/admin/variants-list');
                    }}
                  >
                    New Request
                  </Text>
                </MenuItem>
              </Flex>
            </MenuList>
          </Menu>
        )}
        <Menu>
          <MenuButton
            w="50px"
            h="50px"
            // boxShadow={shadow}
            p="10px"
            borderRadius="35px"
            boxShadow={shadow}
            marginRight={'1rem'}
            // background={'white'}
          >
            <Icon
              // mt="6px"
              as={MdNotificationsNone}
              color={navbarIcon}
              w="28px"
              h="28px"
              me="10px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            // bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: '30px', md: 'unset' }}
            minW={{ base: 'unset', md: '400px', xl: '450px' }}
            maxW={{ base: '360px', md: 'unset' }}
          >
            <Flex w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Notifications
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textColorBrand}
                ms="auto"
                cursor="pointer"
              >
                Mark all read
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                px="0"
                borderRadius="8px"
                mb="10px"
              >
                <ItemContent info="Need to charge BMS" />
              </MenuItem>
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                px="0"
                borderRadius="8px"
                mb="10px"
              >
                <ItemContent info="Shutter Opened" />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            p="0px"
            //  boxShadow={shadow}
          >
            <Avatar
              _hover={{ cursor: 'pointer' }}
              color="white"
              name="Adela Parkson"
              bg="#11047A"
              size="sm"
              w="50px"
              h="50px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            // bg={menuBg}
            border="none"
          >
            <Flex
              w="100%"
              mb="0px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, Adela
              </Text>
              <Button
                variant="no-hover"
                bg="transparent"
                p="0px"
                minW="unset"
                minH="unset"
                h="18px"
                w="max-content"
                mr="20px"
                onClick={() => {
                  toggleColorMode();
                  navigate(
                    `#theme=${colorMode === 'light' ? 'dark' : 'light'}`,
                  );
                }}
              >
                <Icon
                  me="10px"
                  h="18px"
                  w="18px"
                  color={navbarIcon}
                  as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
                />
              </Button>
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Profile Settings</Text>
              </MenuItem>
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Newsletter Settings</Text>
              </MenuItem>
              <MenuItem
                _hover={{ bg: 'none' }}
                _focus={{ bg: 'none' }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={() => {
                  handleLogout();
                  navigate('/auth/sign-in');
                }}
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
      <Modal
        title={``}
        open={openForm}
        footer={null}
        onCancel={resetAndCloseForm}
        width="400px"
      >
        <div className="w-full z-20 mx-auto rounded-none md:rounded-2xl p-4 shadow-input bg-white dark:bg-black">
          <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
            Add Device
          </h2>
          <p className="text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300">
            Add your device by entering the Serial Number and Token just by
            filling out this form.
          </p>

          <form className="my-4" onSubmit={handleSubmit}>
            <LabelInputContainer className="mb-4">
              <Label htmlFor="serial-number">Serial Number</Label>
              <Input
                required={true}
                id="serial-number"
                value={serialNumber}
                placeholder="Enter your device's Serial Number"
                type="text"
                onChange={(e) => setSerialNumber(e.target.value)}
              />
            </LabelInputContainer>
            <LabelInputContainer className="mb-4">
              <Label htmlFor="token">Token</Label>
              <Input
                required={true}
                id="token"
                value={token}
                placeholder="Enter your Token"
                type="text"
                onChange={(e) => setToken(e.target.value)}
              />
            </LabelInputContainer>
            <div className="h-5 mb-4 flex justify-between items-center float-end cursor-pointer">
              <p className="text-neutral-800 text-[14px] max-w-sm mt-0 mb-0 font-medium ease-in duration-300 dark:text-neutral-300">
                Did you forget your token?{' '}
                <span
                  className="cursor-pointer font-bold text-black hover:underline"
                  onClick={() => {
                    // navigate('/auth/sign-in')
                    toast({
                      title: `Coming soon!`,
                      status: 'info',
                      duration: 3000,
                      isClosable: true,
                    });
                  }}
                >
                  Resend it.
                </span>
              </p>
            </div>
            {/* <div className="mt-4 flex items-center justify-center w-full">
              <p className="text-neutral-800 text-[14px] max-w-sm mt-0 mb-0 font-medium ease-in duration-300 dark:text-neutral-300">
                Already have an account?{' '}
                <span
                  className="cursor-pointer font-bold text-black hover:underline"
                  onClick={() => navigate('/auth/sign-in')}
                >
                  Sign In
                </span>
              </p>
            </div> */}
            <Button
              type="submit"
              width="full"
              height="10"
              fontWeight="medium"
              color="white"
              rounded="md"
              bg="gray.800"
              isLoading={buttonLoading}
              sx={{
                _hover: {
                  backgroundColor: 'black',
                },
              }}
            >
              Add Device &rarr;
            </Button>
          </form>
        </div>
      </Modal>

      <ValidateOTP
        openValidateOtpForm={openValidateOtpForm}
        setOpenValidateOtpForm={setOpenValidateOtpForm}
        serialNumber={serialNumber}
        token={token}
        FetchUserProducts={FetchUserProducts}
      />
    </>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn('flex flex-col space-y-2 w-full', className)}>
      {children}
    </div>
  );
};
