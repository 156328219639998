import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  GridItem,
} from '@chakra-ui/react';

// Custom components
import ShutterControl from 'views/Pages/ControlPage/components/ShutterControl';
import BMS from 'views/Pages/ControlPage/components/BMS';
import Status from 'views/Pages/ControlPage/components/Status';
import BatteryLog from 'views/Pages/ControlPage/components/BatteryLog';
import Operations from 'views/Pages/ControlPage/components/Operations';
import Log from 'views/Pages/ControlPage/components/Log';

import { storeDeviceData, readDeviceData } from 'services/localServices';

// WebSocket Example
export default function UserReports() {
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const location = useLocation();

  // State variables
  const [DeviceData, setDeviceData] = useState({});
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [client_id, setClient_id] = useState('web_fd8fd9f');
  const [buttonLoading, setbuttonLoading] = useState({
    rollUp: false,
    pause: false,
    rolldown: false,
  });
  const [buttonDisabled, setbuttonDisabled] = useState({
    rollUp: false,
    pause: false,
    rolldown: false,
  });

  const [ShutterControlData, setShutterControlData] = useState({
    Status: 'Stop', // Opened, Closed, Paused, RollingUp, RollingDown
    LastOperatedAt: 0,
    Remark: '',
    Operation: '', // RollUp, RollDown, Pause
    FOperation: '', //RollingUp, RollingDown,   , RollingUpDone,RollingDownDone
  });
  const [StatusData, setStatusData] = useState({
    Image: 'Online', // DownArrow, UpArrow, Charging, Online, Offline
    Percentage: 0,
    EstimatedTime: 0,
    Status: 'Online',
    Remark: '',
  });
  const [BMSData, setBMSData] = useState({
    Percentage: 0,
    Capacity: 0,
    Temperature: 0,
    OperationsLeft: 0,
    Health: 'Good',
  });
  // Refs
  const socketRef = useRef(null);
  const reconnectTimeout = useRef(null);

  const reconnectInterval = 5000; // 5 seconds

  // WebSocket Connection
  const connectWebSocket = (DeviceData) => {
    if (
      !DeviceData?.serial_number ||
      !DeviceData?.token ||
      !DeviceData?.Chip_id
    ) {
      console.error('DeviceData is incomplete, cannot connect WebSocket.');
      return;
    }

    // const WebsocketURL = `wss://shutterapi.myaccessio.com/ws/product_control1/?serial_number=${
    //   DeviceData.serial_number
    // }&token=${DeviceData.token}&chip_id=${DeviceData.Chip_id}&client_id=web-${
    //   DeviceData.serial_number
    // }-${Math.random().toString(36).substr(2, 8)}`;

    const WebsocketURL = `wss://shutterapi.myaccessio.com/ws/product_control1/?serial_number=${DeviceData.serial_number}&token=${DeviceData.token}&chip_id=${DeviceData.Chip_id}&client_id=${client_id}`;

    console.log('Connecting to WebSocket:', WebsocketURL);

    const socket = new WebSocket(WebsocketURL);
    socketRef.current = socket;

    // Event: Connection opened
    socket.onopen = () => {
      console.log('WebSocket connected');
      setIsConnected(true);
      setLoading(false);
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };

    // Event: Message received
    socket.onmessage = (event) => {
      let Data = JSON.parse(event.data);
      console.log('Message received:', Data);
      let ReceivedData = Data;
      FilterShutterControlData(ReceivedData);

      if (ReceivedData?.bms_data) {
        FilterBMSData(ReceivedData?.bms_data);
      }
    };

    // Event: Error occurred
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // Event: Connection closed
    socket.onclose = () => {
      console.log('WebSocket disconnected');
      setIsConnected(false);
      attemptReconnection();
    };
  };

  // Reconnection logic
  const attemptReconnection = () => {
    if (reconnectTimeout.current) return; // Prevent multiple reconnection attempts
    console.log('Attempting to reconnect...');
    reconnectTimeout.current = setTimeout(() => {
      connectWebSocket(DeviceData);
    }, reconnectInterval);
  };

  // Cleanup on unmount
  useEffect(() => {
    const DeviceData = readDeviceData();
    console.log(DeviceData);
    setDeviceData(DeviceData);

    connectWebSocket(DeviceData);

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, [location]);

  // Send message through WebSocket
  const sendMessage = (Payload) => {
    if (socketRef.current && isConnected) {
      console.log('Payload message:', Payload);
      socketRef.current.send(Payload);
      setInputMessage('');
    } else {
      console.error('WebSocket is not connected');
    }
  };

  // Disconnect WebSocket
  const disconnectWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close();
      setIsConnected(false);
    }
    if (reconnectTimeout.current) {
      clearTimeout(reconnectTimeout.current);
    }
  };

  function FilterShutterControlData(Data) {
    let ReceivedData = Data;
    if (ReceivedData?.feedback_operation === 'RollingUp') {
      setbuttonLoading({ ...buttonLoading, rollUp: false });
      setbuttonDisabled({
        ...buttonDisabled,
        rollUp: true,
        pause: false,
        rollDown: true,
      });
      setShutterControlData({
        ...ShutterControlData,
        Status: 'Rolling Up',
        LastOperatedAt: ReceivedData?.last_operated,
        Remark: ReceivedData?.remark,
      });
      setStatusData({
        ...StatusData,
        Image: 'UpArrow',
        Percentage: ReceivedData?.percentage,
        EstimatedTime: ReceivedData?.estimated_time + 3,
        Remark: ReceivedData?.remark,
      });
    }
    if (ReceivedData?.feedback_operation === 'RollingUpDone') {
      setbuttonLoading({ ...buttonLoading, rollUp: false });
      setbuttonDisabled({
        ...buttonDisabled,
        rollUp: true,
        pause: true,
        rollDown: false,
      });
      setShutterControlData({
        ...ShutterControlData,
        Status: 'Opened',
        LastOperatedAt: ReceivedData?.last_operated,
        Remark: ReceivedData?.remark,
      });
      setStatusData({
        ...StatusData,
        Image: 'Online',
        Status: 'Active',
        Remark: ReceivedData?.remark,
      });
    }
    if (ReceivedData?.feedback_operation === 'RollingDown') {
      setbuttonLoading({ ...buttonLoading, rollDown: false });
      setbuttonDisabled({
        ...buttonDisabled,
        rollUp: true,
        pause: false,
        rollDown: true,
      });
      setShutterControlData({
        ...ShutterControlData,
        Status: 'Rolling Down',
        LastOperatedAt: ReceivedData?.last_operated,
        Remark: ReceivedData?.remark,
      });
      setStatusData({
        ...StatusData,
        Image: 'DownArrow',
        Percentage: ReceivedData?.percentage,
        EstimatedTime: ReceivedData?.estimated_time + 3,
        Remark: ReceivedData?.remark,
      });
    }
    if (ReceivedData?.feedback_operation === 'RollingDownDone') {
      setbuttonLoading({ ...buttonLoading, rollDown: false });
      setbuttonDisabled({
        ...buttonDisabled,
        rollUp: false,
        pause: true,
        rollDown: true,
      });
      setShutterControlData({
        ...ShutterControlData,
        Status: 'Closed',
        LastOperatedAt: ReceivedData?.last_operated,
        Remark: ReceivedData?.remark,
      });
      setStatusData({
        ...StatusData,
        Image: 'Online',
        Status: 'Active',
        Remark: ReceivedData?.remark,
      });
    }
    if (ReceivedData?.feedback_operation === 'Paused') {
      setbuttonLoading({ ...buttonLoading, pause: false });
      setbuttonDisabled({
        ...buttonDisabled,
        rollUp: false,
        pause: true,
        rollDown: false,
      });
      setShutterControlData({
        ...ShutterControlData,
        Status: 'Paused',
        LastOperatedAt: ReceivedData?.last_operated,
        Remark: ReceivedData?.remark,
      });
      setStatusData({
        ...StatusData,
        Image: 'Online',
        Percentage: ReceivedData?.percentage,
        Remark: ReceivedData?.remark,
      });
    }
  }

  function FilterBMSData(Data) {
    let ReceivedData = Data;
    console.log('BMS Data', ReceivedData);
    setBMSData({
      Percentage: ReceivedData?.percentage,
      Capacity: ReceivedData?.capacity,
      Temperature: ReceivedData?.temperature,
      OperationsLeft: ReceivedData?.percentage,
      Health: ReceivedData?.battery_health,
    });
  }

  return (
    <Box pt={{ base: '60px', md: '80px', xl: '80px' }}>
      {/* Grid Layout */}
      <SimpleGrid
        columns={{ base: 1, md: 3, xl: 5 }}
        gap="20px"
        mb="20px"
        // style={{ position: 'relative' }}
      >
        <GridItem colSpan={{ base: 1, md: 3, xl: 2 }}>
          <ShutterControl
            loading={loading}
            buttonLoading={buttonLoading}
            setbuttonLoading={setbuttonLoading}
            buttonDisabled={buttonDisabled}
            setbuttonDisabled={setbuttonDisabled}
            isConnected={isConnected}
            ShutterControlData={ShutterControlData}
            sendMessage={sendMessage}
          />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2, xl: 2 }}>
          <BMS loading={loading} BMSData={BMSData} />
        </GridItem>
        <GridItem rowSpan={{ base: 1, md: 1, xl: 1 }}>
          <Status loading={loading} StatusData={StatusData} />
        </GridItem>
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 3, xl: 5 }}
        gap="20px"
        mb="20px"
        // style={{ position: 'relative' }}
      >
        <GridItem colSpan={{ base: 1, md: 3, xl: 2 }}>
          <Operations loading={loading} />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 2, xl: 2 }}>
          <BatteryLog loading={loading} />
        </GridItem>
        <GridItem colSpan={{ base: 1, md: 1, xl: 1 }}>
          <Log loading={loading} />
        </GridItem>
      </SimpleGrid>

      {/* WebSocket Example Section */}
      <div style={{ padding: '20px' }}>
        <h2>WebSocket Example</h2>
        <div>
          <label>
            Message to send:
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              style={{ marginLeft: '10px', marginRight: '10px' }}
            />
          </label>
          <button onClick={sendMessage} disabled={!isConnected}>
            Send
          </button>
          <button onClick={disconnectWebSocket} disabled={!isConnected}>
            Disconnect
          </button>
        </div>
        <div>
          <h3>
            Connection Status: {isConnected ? 'Connected' : 'Disconnected'}
          </h3>
        </div>
        <div>
          <h3>Messages:</h3>
          <ul>
            {messages.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        </div>
      </div>
    </Box>
  );
}
