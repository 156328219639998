import React, { useState, useEffect } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { Button as AntdButton, ConfigProvider, Flex, Select } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

import category from '../../../api/methods/category';
import Variant from '../../../api/methods/variant';
import ViewComponent from './components/View';
import FormComponent from './components/Form';
import ListComponent from './components/List';
import buttonStyle from '../../../components/button/linearGradient';

export default function CategoryList() {
  // State variables
  const [dataCategory, setDataCategory] = useState([]);
  const [loading, setLoading] = useState({ category: true, variant: false });
  const [data, setData] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [formType, setFormType] = useState('create');
  const [selectedCategory, setSelectedCategory] = useState();

  const toast = useToast();
  const { styles } = buttonStyle();

  // Fetch all category data
  const fetchCategoryData = () => {
    setLoading((prev) => ({ ...prev, category: true }));
    category
      .list()
      .then((response) => {
        const categoryList = response.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setDataCategory(categoryList);
      })
      .finally(() => setLoading((prev) => ({ ...prev, category: false })));
  };

  // Fetch variant data based on selected category
  const fetchData = (id) => {
    const categoryId = id || selectedCategory;
    setSelectedCategory(categoryId);
    setLoading((prev) => ({ ...prev, variant: true }));

    Variant.listByCategory(categoryId)
      .then((response) => {
        const responseData = response.data.map((item) => ({
          key: item?.id,
          id: item?.id,
          name: item?.name,
          description: item?.description,
          features: item?.features ? JSON.parse(item?.features) : item.features,
          MRP: item?.MRP,
          model_number: item?.model_number,
          selling_price: item?.selling_price,
          specifications: item?.specifications,
          discount_percentage: item?.discount_percentage,
          category: item?.category?.id,
          categoryName: item?.category?.name,
          created_date: item?.created_date,
          isActive: item?.is_active,
          image1: item?.image1,
          image2: item?.image2,
          image3: item?.image3,
          image4: item?.image4,
          image5: item?.image5,
          image6: item?.image6,
        }));
        setData(responseData);
      })
      .catch((error) => {
        console.error(error);
        setData([]);
        toast({
          title: error.response?.data?.message || 'Error fetching data',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setLoading((prev) => ({ ...prev, variant: false })));
  };

  // Fetch category data on component mount
  useEffect(() => {
    fetchCategoryData();
  }, []);

  // Handler for adding a new variant
  const handleAddCategory = () => {
    setFormType('create');
    setOpenForm(true);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex className="my-2 justify-between">
        <Select
          placeholder="Select Category"
          style={{ width: 300 }}
          loading={loading.category}
          value={selectedCategory}
          disabled={loading.category}
          options={dataCategory ? dataCategory : []}
          onChange={(value) => {
            fetchData(value);
            setSelectedCategory(value);
          }}
          showSearch
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA.label || '')
              .toLowerCase()
              .localeCompare((optionB.label || '').toLowerCase())
          }
        />
        <ConfigProvider button={{ className: styles.linearGradientButton }}>
          <AntdButton
            onClick={handleAddCategory}
            type="primary"
            icon={<UserAddOutlined />}
            size="large"
            className="float-end w-48 text-lg font-medium"
          >
            Add Variant
          </AntdButton>
        </ConfigProvider>
      </Flex>

      <ListComponent
        data={data}
        fetchData={fetchData}
        setOpenForm={setOpenForm}
        setSelectedData={setSelectedData}
        setFormType={setFormType}
        loading={loading.variant}
        setOpenView={setOpenView}
      />

      <FormComponent
        openForm={openForm}
        setOpenForm={setOpenForm}
        fetchData={fetchData}
        selectedData={selectedData}
        formType={formType}
        dataCategory={dataCategory}
      />

      <ViewComponent
        openView={openView}
        setOpenView={setOpenView}
        selectedData={selectedData}
      />
    </Box>
  );
}
