// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import LineChart from 'components/charts/LineChart';
import React from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { MdBarChart, MdOutlineCalendarToday } from 'react-icons/md';
// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from 'variables/charts';
import { Skeleton, Typography, Space, Tag } from 'antd';

export default function TotalSpent(props) {
  const { loading, ...rest } = props;

  // Chakra Color Mode

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' },
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' },
  );
  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      h="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Battery Level
        </Text>
        <Button
          ms="auto"
          align="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>
      <Box h="240px" w="100%" mt="auto">
        {loading ? (
          <Skeleton.Node
            active
            loading={loading}
            style={{
              height: '200px',
              width: '100%',
              marginBottom: '16px',
              borderRadius: '16px',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                opacity: loading ? 0 : 100,
              }}
            >
              <LineChart
                chartData={lineChartDataTotalSpent}
                chartOptions={lineChartOptionsTotalSpent}
              />
            </div>
          </Skeleton.Node>
        ) : (
          <LineChart
            chartData={lineChartDataTotalSpent}
            chartOptions={lineChartOptionsTotalSpent}
          />
        )}
      </Box>
    </Card>
  );
}
