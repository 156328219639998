import React from 'react';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import { Separator } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';
import BarChart from 'components/charts/BarChart';

// Lottie imports
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import BMSIcon from '../assests/Lottie/BMS.lottie';
import UpArrow from '../assests/Lottie/Up.lottie';
import DownArrow from '../assests/Lottie/Down.lottie';

// Chart imports
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from 'variables/charts';
import { Skeleton, Typography, Space, Tag } from 'antd';

// Icon imports
import { MdBarChart } from 'react-icons/md';
import { FaAngleDoubleUp, FaAngleDoubleDown, FaCloud } from 'react-icons/fa';
import { FaPause, FaKeycdn } from 'react-icons/fa6';
import { RiRemoteControlFill } from 'react-icons/ri';
import {
  LikeOutlined,
  DownloadOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';

// Ant Design imports
import { Col, Row, Statistic, Button as AntdButton, Divider } from 'antd';

// CountUp
import CountUp from 'react-countup';

export default function WeeklyRevenue(props) {
  const { loading, BMSData, ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' },
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' },
  );

  const formatter = (value) => <CountUp end={value} />;

  return (
    <Card align="center" direction="column" w="100%" h="340px" {...rest}>
      {/* Header */}
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          BMS Monitoring
        </Text>
        <Button
          align="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>

      {/* Content */}
      <Box h="240px" px="15px">
        <SimpleGrid columns={3} h="100%">
          {/* Statistics Section */}
          <GridItem colSpan={2}>
            <SimpleGrid columns={5} height="100%">
              <GridItem colSpan={5}>
                <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
                  <Statistic
                    title="Percentage"
                    value={`${BMSData?.Percentage} %`}
                  />
                </Skeleton>
              </GridItem>
              <GridItem colSpan={2}>
                <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
                  <Statistic
                    title="Capacity"
                    value={`${BMSData?.Capacity} Wh`}
                  />
                </Skeleton>
              </GridItem>
              <GridItem colSpan={1}>
                <Divider
                  type="vertical"
                  style={{ height: '80%', margin: 'auto 0' }}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
                  <Statistic
                    title="Operations Left"
                    value={`${BMSData?.OperationsLeft}`}
                  />
                </Skeleton>
              </GridItem>
              <GridItem colSpan={2}>
                <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
                  <Statistic
                    title="Temperature"
                    value={`${BMSData?.Temperature} °C`}
                  />
                </Skeleton>
              </GridItem>
              <GridItem colSpan={1}>
                <Divider
                  type="vertical"
                  style={{ height: '80%', margin: 'auto 0' }}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
                  <Statistic title="Health" value={`${BMSData?.Health}`} />
                </Skeleton>
              </GridItem>
            </SimpleGrid>
          </GridItem>

          {/* Lottie Section */}
          <GridItem colSpan={1}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {loading ? (
                <Skeleton.Node
                  active
                  loading={loading}
                  style={{
                    height: '200px',
                    width: '100%',
                    marginBottom: '16px',
                    borderRadius: '16px',
                  }}
                >
                  <DotLottieReact
                    src={BMSIcon}
                    loop
                    autoplay
                    speed={0.6}
                    style={{ opacity: 0 }}
                  />
                </Skeleton.Node>
              ) : (
                <DotLottieReact src={BMSIcon} loop autoplay speed={0.6} />
              )}
            </div>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Card>
  );
}
