'use client';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Label } from '../../src/AceternityComponents/ui/label.tsx';
import { Input } from '../../src/AceternityComponents/ui/input.tsx';
import { cn } from '../../lib/utils.ts';
import { IconBrandGithub, IconBrandGoogle } from '@tabler/icons-react';
import { useToast, Button } from '@chakra-ui/react';
import authApis from '../../api/methods/auth.jsx';
import { storeEmail } from 'services/localServices.jsx';
export default function SignupForm() {
  const navigate = useNavigate();
  const toast = useToast();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Password validation
    if (password !== confirmPassword) {
      toast({
        title: `Passwords do not match`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setButtonLoading(true);
    authApis
      .register({
        first_name: firstname,
        last_name: lastname,
        username: username,
        email: email,
        password: password,
        confirm_password: confirmPassword,
      })
      .then((res) => {
        console.log(res);
        toast({
          title: 'OTP Sent!',
          description:
            'An OTP has been sent to your email. Please enter it to complete the registration process.',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        storeEmail(email);
        navigate('#VerifyOTP');
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.data?.email?.[0]) {
          toast({
            title: 'Sign-Up Error',
            description: e.response?.data?.email[0],
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        } else if (e.response?.data?.non_field_errors[0]) {
          toast({
            title: 'Sign-Up Error',
            description: e.response?.data?.non_field_errors[0],
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  return (
    <div className="max-w-md w-full z-20 mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white dark:bg-black">
      <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
        Join Effortless Shutter Control
      </h2>
      <p className="text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300">
        Create an account to manage your shutters effortlessly.
      </p>

      <form className="my-8" onSubmit={handleSubmit}>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
          <LabelInputContainer>
            <Label htmlFor="firstname">First name</Label>
            <Input
              id="firstname"
              placeholder="First Name"
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
          </LabelInputContainer>
          <LabelInputContainer>
            <Label htmlFor="lastname">Last name</Label>
            <Input
              id="lastname"
              placeholder="Last Name"
              type="text"
              required
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </LabelInputContainer>
        </div>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="username">Username</Label>
          <Input
            id="username"
            placeholder="Username"
            type="text"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="email">Email Address</Label>
          <Input
            id="email"
            placeholder="you@example.com"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </LabelInputContainer>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
          <LabelInputContainer>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              placeholder="••••••••"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </LabelInputContainer>
          <LabelInputContainer>
            <Label htmlFor="confirmPassword">Confirm Password</Label>
            <Input
              id="confirmPassword"
              placeholder="••••••••"
              type="password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </LabelInputContainer>
        </div>

        <Button
          type="submit"
          // variant="unstyled" // Removes default styling
          width="full"
          height="10"
          fontWeight="medium"
          color="white"
          rounded="md"
          bg="gray.800"
          isLoading={buttonLoading}
          sx={{
            _hover: {
              backgroundColor: 'black',
            }, // Removes the hover effect
          }}
        >
          Sign up &rarr;
          <BottomGradient />
        </Button>

        {/* <button
          className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
          type="submit"
        >
          Sign up &rarr;
          <BottomGradient />
        </button> */}
        <div className="mt-4 flex items-center justify-center w-full">
          <p className="text-neutral-800 text-[14px] max-w-sm mt-0 mb-0 font-medium ease-in duration-300 dark:text-neutral-300">
            Already have an account?{' '}
            <span
              className="cursor-pointer font-bold text-black hover:underline"
              onClick={() => navigate('/auth/sign-in')}
            >
              Sign In
            </span>
          </p>
        </div>
        <div className="bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent mt-6 mb-4 h-[1px] w-full" />

        <div className="flex ">
          <button
            className="relative group/btn flex space-x-2 mx-2 items-center justify-start px-4 w-1/2 text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]"
            type="button"
          >
            <IconBrandGoogle className="h-4 w-4 text-neutral-800 dark:text-neutral-300" />
            <span className="text-neutral-700 dark:text-neutral-300 text-sm">
              Google
            </span>
            <BottomGradient />
          </button>
          <button
            className="relative group/btn flex space-x-2 mx-2 items-center justify-start px-4 w-1/2 text-black rounded-md h-10 font-medium shadow-input bg-gray-50 dark:bg-zinc-900 dark:shadow-[0px_0px_1px_1px_var(--neutral-800)]"
            type="button"
          >
            <IconBrandGithub className="h-4 w-4 text-neutral-800 dark:text-neutral-300" />
            <span className="text-neutral-700 dark:text-neutral-300 text-sm">
              Github
            </span>
            <BottomGradient />
          </button>
        </div>
      </form>
    </div>
  );
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn('flex flex-col space-y-2 w-full', className)}>
      {children}
    </div>
  );
};
