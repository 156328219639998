// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import moment from 'moment';

// Custom components
import BarChart from 'components/charts/BarChart';
import React, { useState, useEffect } from 'react';
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from 'variables/charts';
import { MdBarChart } from 'react-icons/md';
import CountUp from 'react-countup';
import { FaAngleDoubleUp, FaAngleDoubleDown, FaCloud } from 'react-icons/fa';
import { FaPause, FaKeycdn } from 'react-icons/fa6';
import { storeDeviceData, readDeviceData } from 'services/localServices';

import { RiRemoteControlFill } from 'react-icons/ri';
import UpArrow from '../assests/Lottie/Up.lottie';
import DownArrow from '../assests/Lottie/Down.lottie';
import {
  LikeOutlined,
  DownloadOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';
import { Skeleton, Typography, Space, Tag } from 'antd';
import { Col, Row, Statistic, Button as AntdButton } from 'antd';
export default function ShutterControl(props) {
  const {
    loading,
    buttonLoading,
    setbuttonLoading,
    buttonDisabled,
    setbuttonDisabled,
    isConnected,
    ShutterControlData,
    sendMessage,
    ...rest
  } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' },
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' },
  );
  const formatter = (value) => <CountUp end={value} />;
  const { Title } = Typography;
  const [DeviceData, setDeviceData] = useState({});
  useEffect(() => {
    const DeviceData = readDeviceData();
    console.log(DeviceData);
    setDeviceData(DeviceData);
  }, []);
  return (
    <Card align="center" direction="column" w="100%" h="340px" {...rest}>
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Control Your Shutter
        </Text>
        <Button
          align="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>
      <Box
        h={'240px'}
        px="15px"
        // style={{ height: '100%' }}
      >
        <SimpleGrid columns={3} height={'70%'}>
          <GridItem colSpan={1}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                align="center"
                justifyContent="center"
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w="74px"
                h="74px"
                lineHeight="100%"
                borderRadius="10px"
                mb="10px"
                isLoading={buttonLoading.rollUp}
                disabled={
                  loading || buttonLoading.rollUp || buttonDisabled.rollUp
                }
                onClick={() => {
                  sendMessage(
                    JSON.stringify({
                      type: 'web',
                      serial_number: `${DeviceData?.serial_number}`,
                      operation: 'RollUp',
                    }),
                  );
                  setbuttonLoading({ ...buttonLoading, rollUp: true });
                  setbuttonDisabled({ ...buttonDisabled, rollUp: true });
                }}
              >
                <Icon
                  as={FaAngleDoubleUp}
                  color={iconColor}
                  w="48px"
                  h="48px"
                />
              </Button>
              <Text
                // me="auto"
                color={textColor}
                fontSize="lg"
                fontWeight="700"
                lineHeight="100%"
              >
                Roll Up
              </Text>
            </div>
          </GridItem>
          <GridItem colSpan={1}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                align="center"
                justifyContent="center"
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w="74px"
                h="74px"
                lineHeight="100%"
                borderRadius="10px"
                mb="10px"
                isLoading={buttonLoading.pause}
                disabled={
                  loading || buttonLoading.pause || buttonDisabled.pause
                }
                onClick={() => {
                  sendMessage(
                    JSON.stringify({
                      type: 'web',
                      serial_number: `${DeviceData?.serial_number}`,
                      operation: 'Pause',
                    }),
                  );
                  setbuttonLoading({ ...buttonLoading, pause: true });
                  setbuttonDisabled({ ...buttonDisabled, pause: true });
                }}
              >
                <Icon as={FaPause} color={iconColor} w="48px" h="48px" />
              </Button>
              <Text
                // me="auto"
                color={textColor}
                fontSize="lg"
                fontWeight="700"
                lineHeight="100%"
              >
                Stop
              </Text>
            </div>
          </GridItem>
          <GridItem colSpan={1}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                align="center"
                justifyContent="center"
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w="74px"
                h="74px"
                lineHeight="100%"
                borderRadius="10px"
                mb="10px"
                isLoading={buttonLoading.rollDown}
                disabled={
                  loading || buttonLoading.rollDown || buttonDisabled.rollDown
                }
                onClick={() => {
                  sendMessage(
                    JSON.stringify({
                      type: 'web',
                      serial_number: `${DeviceData?.serial_number}`,
                      operation: 'RollDown',
                    }),
                  );
                  setbuttonLoading({ ...buttonLoading, rollDown: true });
                  setbuttonDisabled({ ...buttonDisabled, rollDown: true });
                }}
              >
                <Icon
                  as={FaAngleDoubleDown}
                  color={iconColor}
                  w="48px"
                  h="48px"
                />
              </Button>
              <Text
                // me="auto"
                color={textColor}
                fontSize="lg"
                fontWeight="700"
                lineHeight="100%"
              >
                Roll Down
              </Text>
            </div>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid
          columns={3}
          spacing={0} // Sets gap between grid items to 0
          m={0} // Removes margin
          p={0} // Removes padding
        >
          <GridItem colSpan={1}>
            <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
              <Statistic
                title="Status"
                value={`${ShutterControlData?.Status}`}
              />
            </Skeleton>
          </GridItem>
          <GridItem colSpan={2}>
            <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
              <Statistic
                title="Last Operated At"
                value={`${moment
                  .unix(ShutterControlData?.LastOperatedAt)
                  .format('MMM Do YYYY, h:mm:ss a')}`}
              />
            </Skeleton>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Card>
  );
}
