import { mode } from '@chakra-ui/theme-tools';
export const globalStyles = {
  colors: {
    brand: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#11047A',
    },
    brandScheme: {
      100: '#E9E3FF',
      200: '#7551FF',
      300: '#7551FF',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#02044A',
    },
    brandTabs: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#422AFB',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#02044A',
    },
    secondaryGray: {
      100: '#F5F5F5', // Very light gray for subtle backgrounds
      200: '#EAEAEA', // Light gray for section dividers
      300: '#F2F2F2', // Match the light mode background
      400: '#D9D9D9', // Slightly darker gray for subtle contrast
      500: '#B3B3B3', // Mid-gray for text accents
      600: '#8C8C8C', // Darker gray for primary text in light mode
      700: '#666666', // For medium contrast text or icons
      800: '#4D4D4D', // Used sparingly for darker elements
      900: '#282828', // Match the dark mode background
    },
    red: {
      100: '#FEEFEE',
      500: '#EE5D50',
      600: '#E31A1A',
    },
    blue: {
      50: '#EFF4FB',
      500: '#3965FF',
    },
    orange: {
      100: '#FFF6DA',
      500: '#FFB547',
    },
    green: {
      100: '#E6FAF5',
      500: '#01B574',
    },

    navy: {
      50: '#E6E6E6', // Very light grayish-blue for light highlights
      100: '#CCCCCC', // Light grayish-blue for soft contrasts
      200: '#B3B3B3', // Light-medium tones
      300: '#999999', // Neutral medium tones for light mode
      400: '#666666', // Complementary to the dark mode palette
      500: '#444444', // Stronger contrast in dark mode
      600: '#333333', // Supporting color for dark mode
      700: '#282828', // Match the dark mode background
      800: '#1A1A1A', // Darker tones for sharp contrasts
      900: '#0F0F0F', // Almost black for maximum contrast
    },
    gray: {
      100: '#FAFCFE',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: 'hidden',
        bg: mode('secondaryGray.300', 'navy.900')(props),
        fontFamily: 'DM Sans',
        letterSpacing: '-0.5px',
      },
      input: {
        color: 'gray.700',
      },
      html: {
        fontFamily: 'DM Sans',
      },
    }),
  },
};
