import './assets/css/App.css';
import './assets/css/globals.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
// import RTLLayout from './layouts/rtl';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { isProduction } from './services/DataConfig';
import { isLoggedIn } from 'services/localServices';
import { setupAxiosInterceptors } from 'api/client';

// Antd
import AntdConfig from './theme/antdConfig';
import { isDarkTheme } from './services/localServices';
import { ConfigProvider } from 'antd';

export default function Main() {
  const location = useLocation();
  const toast = useToast();

  const [isLoggedInState, setIsLoggedInState] = useState(isLoggedIn());
  const [isDarkMode, setIsDarkMode] = useState(false);
  let ConfigData = AntdConfig(isDarkMode);

  // For Alert Messages
  useEffect(() => {
    setupAxiosInterceptors(toast);
  }, [toast]);

  function ChangeTheme() {
    if (isDarkTheme() === 'dark') {
      setIsDarkMode(true);
      ConfigData = AntdConfig(true);
    } else {
      setIsDarkMode(false);
      ConfigData = AntdConfig(false);
    }
  }

  useEffect(() => {
    ChangeTheme();
    if (isProduction) {
      console = {};
      console.log = function () {};
      console.info = function () {};
      console.warn = function () {};
      console.error = function () {};
    }
  }, []);

  useEffect(() => {
    setIsLoggedInState(isLoggedIn());
    console.log('Login State Changed');
  }, [location.pathname]);

  useEffect(() => {
    ChangeTheme();
    console.log('Theme State Changed');
  }, [location.hash]);

  const [currentTheme, setCurrentTheme] = useState(initialTheme); // For Chakra UI Theme

  return (
    <ConfigProvider
      componentSize="large"
      theme={{
        ...ConfigData,
      }}
    >
      <ChakraProvider theme={currentTheme}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {isLoggedInState ? (
              <>
                <Route
                  path="admin/*"
                  element={
                    <AdminLayout
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  }
                />
                <Route path="/*" element={<Navigate to="/admin" replace />} />
              </>
            ) : (
              <>
                <Route path="auth/*" element={<AuthLayout />} />
                <Route
                  path="/*"
                  element={<Navigate to="/auth/sign-in" replace />}
                />
              </>
            )}
          </Routes>
        </Suspense>
      </ChakraProvider>
    </ConfigProvider>
  );
}
