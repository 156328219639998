import apiEndpoints from '../endpoints';
import apiClient from '../client';

const category = {
  list: () => {
    return apiClient.get(`${apiEndpoints.category.list}`);
  },
  listWithVariant: () => {
    return apiClient.get(`${apiEndpoints.category.listWithVariant}`);
  },
  create: (body) => {
    return apiClient.post(`${apiEndpoints.category.create}`, body);
  },
  update: (body, id) => {
    return apiClient.put(`${apiEndpoints.category.update}${id}/`, body);
  },
  delete: (id) => {
    return apiClient
      .delete(`${apiEndpoints.category.delete}${id}/`)
      .catch(() => {});
  },
};

export default category;
