import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Input, Upload, Select, Switch } from 'antd';
import { useToast } from '@chakra-ui/react';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

// API methods and configurations
import category from '../../../../api/methods/category';
import { Base_URL } from 'services/DataConfig';

export default function FormComponent({
  openForm,
  setOpenForm,
  fetchData,
  selectedData,
  formType,
}) {
  // State variables
  const [form] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const toast = useToast();

  // Effect to load selected data when formType is 'edit'
  useEffect(() => {
    if (formType === 'edit') {
      initializeEditForm();
    } else if (formType === 'create') {
      resetForm();
    }
  }, [formType, selectedData]);

  // Initialize form fields for editing
  const initializeEditForm = () => {
    form.setFieldsValue({ ...selectedData });
    setFeatureList(selectedData.features || []);
    setIsActive(selectedData.isActive);
    const images = [];

    if (selectedData.image1)
      images.push({
        uid: '1',
        name: 'Image',
        url: `${Base_URL}${selectedData.image1}`,
      });
    if (selectedData.image2)
      images.push({
        uid: '2',
        name: 'Image',
        url: `${Base_URL}${selectedData.image2}`,
      });
    if (selectedData.image3)
      images.push({
        uid: '3',
        name: 'Image',
        url: `${Base_URL}${selectedData.image3}`,
      });

    setFileList(images);
  };

  // Reset form fields for creation
  const resetForm = () => {
    setFileList([]);
    setFeatureList([]);
  };

  // Helper to handle file or object
  const handleFileOrObject = async (file, formData, keyName) => {
    if (file instanceof File) {
      formData.append(keyName, file);
    } else if (typeof file === 'object') {
      try {
        const response = await fetch(file.url);
        if (!response.ok) throw new Error('Failed to fetch image');

        const imageBlob = await response.blob();
        formData.append(keyName, imageBlob, `${keyName}.jpg`);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  };

  // Upload properties
  const uploadProps = {
    onRemove: (file) => {
      setFileList(fileList.filter((item) => item.uid !== file.uid));
    },
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        toast({
          title: 'You can only upload JPG/PNG file!',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
      setFileList((prevList) => [...prevList, file]);
      return false;
    },
    fileList,
  };

  // Form submission handler
  const onFinish = async (values) => {
    if (fileList.length === 0) {
      showToast('Please upload at least one image!', 'info');
      return;
    }
    if (featureList.length === 0) {
      showToast('Please add at least one feature!', 'info');
      return;
    }

    setFormSubmitting(true);
    const formData = createFormData(values);

    const action = formType === 'edit' ? category.update : category.create;
    const successMessage =
      formType === 'edit'
        ? 'Category Updated Successfully!'
        : 'Category Created Successfully!';
    const description = `Your ${
      formType === 'edit' ? 'updated' : 'new'
    } category is ready. Start organizing effortlessly.`;

    action(formData, selectedData?.id)
      .then(() => {
        showToast(successMessage, 'success', description);
        resetAndCloseForm();
        fetchData();
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => setFormSubmitting(false));
  };

  // Helper to create FormData
  const createFormData = (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('is_active', isActive);
    formData.append('features', JSON.stringify(featureList));

    for (let i = 0; i < 3; i++) {
      if (i < fileList.length) {
        handleFileOrObject(fileList[i], formData, `image${i + 1}`);
      } else {
        formData.append(`image${i + 1}`, '');
      }
    }
    return formData;
  };

  // Helper to show toast
  const showToast = (title, status, description = '') => {
    toast({ title, description, status, duration: 3000, isClosable: true });
  };

  // Reset and close form
  const resetAndCloseForm = () => {
    form.resetFields();
    setFileList([]);
    setFeatureList([]);
    setOpenForm(false);
  };

  // Form submission failure handler
  const onFinishFailed = (errorInfo) => {
    showToast(
      errorInfo.errorFields[0]?.errors[0] || 'Form submission failed!',
      'info',
    );
  };

  return (
    <Modal
      title={`${
        formType === 'edit' ? 'Update' : 'Create'
      } Category by completing the form below!`}
      open={openForm}
      footer={null}
      onCancel={resetAndCloseForm}
      width="600px"
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        size="large"
      >
        <Form.Item
          name="name"
          label="Name of the Category"
          rules={[{ required: true, message: 'Please enter the Name!' }]}
        >
          <Input placeholder="Enter category name" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the Description!' }]}
        >
          <Input.TextArea placeholder="Enter category description" rows={3} />
        </Form.Item>
        <Form.Item name="features" label="Features">
          <Select
            mode="tags"
            style={{ width: '100%' }}
            value={featureList}
            onChange={setFeatureList}
            placeholder="Add features separated by commas"
            tokenSeparators={[',']}
            options={[]}
          />
        </Form.Item>
        {formType === 'edit' && (
          <Form.Item name="is_active" label="Is Active">
            <Switch checked={isActive} onChange={setIsActive} />
          </Form.Item>
        )}
        <Form.Item name="FilePath" label="Upload Images">
          <ImgCrop rotationSlider aspect={16 / 9}>
            <Upload listType="picture-card" {...uploadProps}>
              {fileList.length < 3 && <PlusOutlined />}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={formSubmitting}
            style={{ width: '200px' }}
          >
            {formType === 'edit' ? 'Update Category' : 'Create Category'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
