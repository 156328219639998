import apiEndpoints from '../endpoints';
import apiClient from '../client';

const userProduct = {
  list: () => {
    return apiClient.get(`${apiEndpoints.userProduct.list}`);
  },
  add: (body) => {
    return apiClient.post(`${apiEndpoints.userProduct.add}`, body);
  },
  addVerify: (body) => {
    return apiClient.post(`${apiEndpoints.userProduct.addVerify}`, body);
  },
};

export default userProduct;
