import React, { useState, useEffect } from 'react';
import { Modal, Descriptions, Image, Tag, Typography } from 'antd';
import moment from 'moment';
import { Base_URL } from 'services/DataConfig';

export default function ViewComponent({ openView, setOpenView, selectedData }) {
  const [details, setDetails] = useState([]);
  const { Text } = Typography;

  // Helper function to filter data
  const filterData = (data) => (data ? data : '-');

  // Prepare row details
  useEffect(() => {
    if (!selectedData) return;

    const rowDetails = [
      {
        key: '1',
        label: 'Name',
        span: 3,
        children: filterData(selectedData.name),
      },
      {
        key: '2',
        label: 'Description',
        span: 3,
        children: filterData(selectedData.description),
      },
      {
        key: '3',
        label: 'Status',
        span: 1,
        children: selectedData.is_active ? 'Active' : 'Inactive',
      },
      {
        key: '4',
        label: 'Model Number',
        span: 2,
        children: filterData(selectedData.model_number),
      },
      {
        key: '5',
        label: 'MRP',
        span: 1,
        children: filterData(selectedData.MRP),
      },
      {
        key: '6',
        label: 'Discount',
        span: 1,
        children: `${filterData(selectedData.discount_percentage)} %`,
      },
      {
        key: '7',
        label: 'Sale Price',
        span: 1,
        children: filterData(selectedData.selling_price),
      },
      {
        key: '8',
        label: 'Category Name',
        span: 3,
        children: filterData(selectedData.categoryName),
      },
      {
        key: '9',
        label: 'Created Date',
        span: 3,
        children: filterData(
          moment(selectedData.created_date).format('MMMM Do YYYY, h:mm:ss a'),
        ),
      },
      {
        key: '10',
        label: 'Features',
        span: 3,
        children: (
          <>
            {selectedData.features &&
              Object.entries(selectedData.features).map(([key, value]) => (
                <Tag color="blue" key={key}>
                  {value}
                </Tag>
              ))}
          </>
        ),
      },
      {
        key: '11',
        label: 'Specifications',
        span: 3,
        children: (
          <>
            {selectedData.specifications &&
              selectedData.specifications.map((field, index) => (
                <div key={index}>
                  <Text strong>{field.key}:</Text> {field.value}
                </div>
              ))}
          </>
        ),
      },
      {
        key: '12',
        label: 'Gallery',
        span: 3,
        children: (
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '12px',
            }}
          >
            <Image.PreviewGroup>
              {[1, 2, 3, 4, 5, 6].map((num) => {
                const imageUrl = selectedData[`image${num}`];
                return (
                  imageUrl && (
                    <Image
                      key={num}
                      width={150}
                      src={`${Base_URL}${imageUrl}`}
                    />
                  )
                );
              })}
            </Image.PreviewGroup>
          </div>
        ),
      },
    ];

    setDetails(rowDetails);
  }, [selectedData]);

  return (
    <Modal
      open={openView}
      footer={null}
      onCancel={() => setOpenView(false)}
      width="800px"
    >
      <Descriptions
        bordered
        size="medium"
        title="Variant Details"
        layout="vertical"
        column={3}
      >
        {details.map(({ key, label, span, children }) => (
          <Descriptions.Item key={key} label={label} span={span}>
            {children}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Modal>
  );
}
