'use client';
import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { Boxes } from '../../src/AceternityComponents/ui/background-boxes.tsx';
import { cn } from '../../lib/utils.ts';
import SignUpForm from 'NewComponents/Auth/SignUpForm.jsx';
import SignInForm from 'NewComponents/Auth/SignInForm.jsx';
import ForgotPasswordForm from 'NewComponents/Auth/ForgotPassword.jsx';
import ChangePasswordForm from 'NewComponents/Auth/ChangePassword.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading1 from '../../assets/lottie/Loading1.json';
export default function SignIn() {
  const [Page, setPage] = useState('#SignIn');

  const location = useLocation();
  //
  useEffect(() => {
    let hash = location.hash;
    const cleanHash = hash ? hash.split('?')[0] : null;
    console.log(cleanHash);
    if (cleanHash === '#ForgotPassword') {
      setPage('#ForgotPassword');
    } else if (cleanHash === '#ChangePassword') {
      setPage('#ChangePassword');
    } else {
      setPage('#SignIn');
    }
  }, [location.hash]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading1,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="h-screen relative w-full overflow-hidden bg-slate-900 flex flex-col items-center justify-center rounded-lg">
      <div className="absolute inset-0 w-full h-full bg-slate-900 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />
      <Boxes />
      {/* <Lottie options={defaultOptions} height={400} width={400} /> */}
      {Page === '#SignIn' && <SignInForm />}
      {Page === '#ForgotPassword' && <ForgotPasswordForm />}
      {Page === '#ChangePassword' && <ChangePasswordForm />}
    </div>
  );
}
