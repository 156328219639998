import React, { useState } from 'react';

// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';
import BarChart from 'components/charts/BarChart';

// Ant Design imports
import { Col, Row, Statistic, Button } from 'antd';

import { SmileOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';

// Chart imports
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from 'variables/charts';

// Icons
import { MdBarChart } from 'react-icons/md';
import { Skeleton, Typography, Space, Tag } from 'antd';

// Utilities
import CountUp from 'react-countup';

export default function WeeklyRevenue(props) {
  const { loading, ...rest } = props;
  const { Countdown } = Statistic;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' },
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' },
  );

  const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Example deadline

  // State for animated value
  const [value, setValue] = useState({
    startValue: 0,
    endValue: 0,
  });

  // Value formatter component
  function ValueFormatter({ startValue, endValue }) {
    return <CountUp start={startValue} end={endValue} />;
  }

  return (
    <Card align="center" direction="column" w="100%" h="100%" {...rest}>
      {/* Title */}
      <Text
        me="auto"
        color={textColor}
        fontSize="xl"
        fontWeight="700"
        lineHeight="100%"
      >
        Log
      </Text>
      <div
        style={{
          overflowY: loading ? 'unset' : 'scroll',
          height: '300px',
          width: '100%',
          padding: '1rem 0.5rem',
        }}
      >
        {loading ? (
          <Skeleton.Node
            active
            loading={loading}
            style={{
              height: '240px',
              width: '180px',
              marginBottom: '16px',
              borderRadius: '16px',
              marginTop: '16px',
            }}
          ></Skeleton.Node>
        ) : (
          <Timeline
            style={{ opacity: loading ? 0 : 100 }}
            items={[
              {
                color: 'green',
                children: 'Create a services site 2015-09-01',
              },
              {
                color: 'green',
                children: 'Create a services site 2015-09-01',
              },
              {
                color: 'red',
                children: (
                  <>
                    <p>Solve initial network problems 1</p>
                    <p>Solve initial network problems 2</p>
                    <p>Solve initial network problems 3 2015-09-01</p>
                  </>
                ),
              },
              {
                children: (
                  <>
                    <p>Technical testing 1</p>
                    <p>Technical testing 2</p>
                    <p>Technical testing 3 2015-09-01</p>
                  </>
                ),
              },
              {
                color: 'gray',
                children: (
                  <>
                    <p>Technical testing 1</p>
                    <p>Technical testing 2</p>
                    <p>Technical testing 3 2015-09-01</p>
                  </>
                ),
              },
              {
                color: 'gray',
                children: (
                  <>
                    <p>Technical testing 1</p>
                    <p>Technical testing 2</p>
                    <p>Technical testing 3 2015-09-01</p>
                  </>
                ),
              },
              {
                color: '#00CCFF',
                dot: <SmileOutlined />,
                children: <p>Custom color testing</p>,
              },
            ]}
          />
        )}
      </div>
    </Card>
  );
}
