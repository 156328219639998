import apiEndpoints from '../endpoints';
import apiClient from '../client';

const product = {
  list: () => {
    return apiClient.get(`${apiEndpoints.product.list}`);
  },
  listByBatch: (id) => {
    return apiClient.get(`${apiEndpoints.product.listByBatch}${id}/`);
  },
  create: (body) => {
    return apiClient.post(`${apiEndpoints.product.create}`, body);
  },
  update: (body, id) => {
    return apiClient.put(`${apiEndpoints.product.update}${id}/`, body);
  },
  delete: (id) => {
    return apiClient
      .delete(`${apiEndpoints.product.delete}${id}/`)
      .catch(() => {});
  },
};

export default product;
