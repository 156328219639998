import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Button,
  Input,
  DatePicker,
  Upload,
  Select,
  Switch,
} from 'antd';
import { useToast } from '@chakra-ui/react';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// API methods and configurations
import category from '../../../../api/methods/category';
import variant from '../../../../api/methods/variant';
import batch from '../../../../api/methods/batch';
import { Base_URL } from 'services/DataConfig';
const { RangePicker } = DatePicker;
export default function FormComponent({
  openForm,
  setOpenForm,
  fetchData,
  selectedData,
  setSelectedData,
  formType,
}) {
  // State variables
  const [form] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const toast = useToast();

  const [TimeInterval, setTimeInterval] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedVariant, setSelectedVariant] = useState('');
  const [formDataLoading, setFormDataLoading] = useState({
    category: false,
    variant: false,
  });

  const [showRangePicker, setShowRangePicker] = useState(false);
  // Effect to load selected data when formType is 'edit'
  useEffect(() => {
    if (formType === 'edit') {
      initializeEditForm();
    } else if (formType === 'create') {
      resetForm();
    }
  }, [formType, selectedData]);

  useEffect(() => {}, [showRangePicker]);

  useEffect(() => {
    fetchCategoryData();
  }, []);

  // Initialize form fields for editing
  const initializeEditForm = () => {
    console.log(selectedData);
    setShowRangePicker(false);
    let TempTimeInterval = [
      dayjs(selectedData?.batch_start_date, 'YYYY-MM-DD'),
      dayjs(selectedData?.batch_end_date, 'YYYY-MM-DD'),
    ];
    setTimeInterval(TempTimeInterval);
    fetchVariantData(selectedData?.varients.category.id);
    form.setFieldsValue({
      ...selectedData,
      category: selectedData?.varients?.category?.id,
      variant: selectedData?.varients.id,
      time_interval: TempTimeInterval,
    });
    setTimeout(() => {
      setShowRangePicker(true);
    }, 100);
  };

  // Reset form fields for creation
  const resetForm = () => {
    setShowRangePicker(false);
    setTimeInterval([]);
    setTimeout(() => {
      setShowRangePicker(true);
    }, 100);
  };

  // Form submission handler
  const onFinish = async (values) => {
    setFormSubmitting(true);
    const formData = createFormData(values);

    const action = formType === 'edit' ? batch.update : batch.create;
    const successMessage =
      formType === 'edit'
        ? 'Batch Updated Successfully!'
        : 'Batch Created Successfully!';
    const description = `Your ${
      formType === 'edit' ? 'updated' : 'new'
    } Batch is ready. Start organizing effortlessly.`;

    action(formData, selectedData?.id)
      .then(() => {
        showToast(successMessage, 'success', description);
        resetAndCloseForm();
        fetchData();
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => setFormSubmitting(false));
  };

  // Helper to create FormData
  const createFormData = (values) => {
    values.time_interval = values.time_interval.map((dateTime) => {
      const date = new Date(dateTime);
      const offsetInMinutes = date.getTimezoneOffset() + 660; // 330 minutes for +5:30
      date.setMinutes(date.getMinutes() + offsetInMinutes);
      console.log(date);
      console.log(date.toISOString().split('T'));
      return date.toISOString().split('T')[0];
    });
    console.log(values);
    const formData = new FormData();
    formData.append('batch_name', values.name);
    formData.append('variant', values.variant);
    formData.append('batch_start_date', values.time_interval[0]);
    formData.append('batch_end_date', values.time_interval[1]);
    formData.append('remarks', values?.remarks);
    return formData;
  };

  // Helper to show toast
  const showToast = (title, status, description = '') => {
    toast({ title, description, status, duration: 3000, isClosable: true });
  };

  // Reset and close form
  const resetAndCloseForm = () => {
    form.resetFields();
    setTimeInterval([]);
    setOpenForm(false);
    // setSelectedData({});
  };

  // Form submission failure handler
  const onFinishFailed = (errorInfo) => {
    showToast(
      errorInfo.errorFields[0]?.errors[0] || 'Form submission failed!',
      'info',
    );
  };

  // Function to fetch category data
  const fetchCategoryData = () => {
    setFormDataLoading({ ...formDataLoading, category: true });
    category
      .list()
      .then((response) => {
        const categoryList = response.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setCategoryList(categoryList);
      })
      .finally(() => {
        setFormDataLoading((prev) => ({ ...prev, category: false }));
      });
  };

  // Function to fetch variant data
  const fetchVariantData = (id) => {
    setFormDataLoading({ ...formDataLoading, variant: true });
    variant
      .listByCategory(id)
      .then((response) => {
        if (response?.message) {
          setVariantList([]);
          showToast('No Variant found for this category', 'info');
        } else {
          const variantList = response.data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          setVariantList(variantList);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        if (error?.message) {
          setVariantList([]);
          setSelectedVariant('');
          showToast('No Variant found for this category', 'info');
        }
      })
      .finally(() => {
        setFormDataLoading((prev) => ({ ...prev, variant: false }));
      });
  };

  return (
    <Modal
      title={`${
        formType === 'edit' ? 'Update' : 'Create'
      } Batch by completing the form below!`}
      open={openForm}
      footer={null}
      onCancel={resetAndCloseForm}
      width="600px"
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        size="large"
      >
        <Form.Item
          name="name"
          label="Name of the Batch"
          rules={[{ required: true, message: 'Please enter the Name!' }]}
        >
          <Input placeholder="Enter Batch name" />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: 'Please select the Category!' }]}
        >
          <Select
            placeholder="Select Category"
            // style={{ width: 300 }}
            loading={setFormDataLoading.category}
            value={selectedCategory}
            disabled={setFormDataLoading.category}
            options={categoryList ? categoryList : []}
            onChange={(value) => {
              setVariantList([]);
              setSelectedVariant('');
              form.resetFields(['variant']);
              fetchVariantData(value);
              setSelectedCategory(value);
            }}
            showSearch
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA.label || '')
                .toLowerCase()
                .localeCompare((optionB.label || '').toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="variant"
          label="Variant"
          rules={[{ required: true, message: 'Please select the Variant!' }]}
        >
          <Select
            placeholder="Select Variant"
            // style={{ width: 300 }}
            loading={setFormDataLoading.variant}
            value={selectedVariant}
            disabled={setFormDataLoading.variant}
            options={variantList ? variantList : []}
            onChange={(value) => {
              setSelectedVariant(value);
            }}
            showSearch
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA.label || '')
                .toLowerCase()
                .localeCompare((optionB.label || '').toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          name="time_interval"
          label="Name of the Batch"
          rules={[
            { required: true, message: 'Please select the Time Interval!' },
          ]}
        >
          {showRangePicker && (
            <RangePicker
              format="YYYY-MM-DD"
              style={{ width: '100%' }}
              // value={TimeInterval}
              defaultValue={TimeInterval}
              value={TimeInterval}
              onChange={(e) => {
                setTimeInterval(e);
                console.log(e);
              }}
            />
          )}
        </Form.Item>
        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea placeholder="Enter Batch Remarks" rows={3} />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={formSubmitting}
            style={{ width: '200px' }}
          >
            {formType === 'edit' ? 'Update Batch' : 'Create Batch'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
