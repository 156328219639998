import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Button as AntdButton, ConfigProvider } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

// API methods and custom components

import batch from '../../../api/methods/batch';
import ViewComponent from './components/View';
import FormComponent from './components/Form';
import ListComponent from './components/List';
import buttonStyle from '../../../components/button/linearGradient';

export default function CategoryList() {
  // State variables
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [formType, setFormType] = useState('create');

  // Custom button styles
  const { styles } = buttonStyle();

  // Function to fetch batch data
  const fetchData = () => {
    setLoading(true);
    batch
      .list()
      .then((response) => {
        console.log('Batch List Response:', response.data);
        const responseData = response.data.map((data) => ({
          key: data?.id,
          id: data?.id,
          name: data?.batch_name,
          remarks: data?.remarks,
          created_date: data?.created_date,
          batch_number: data?.batch_number,
          batch_start_date: data?.batch_start_date,
          batch_end_date: data?.batch_end_date,
          varients: data?.variant,
        }));
        setData(responseData);
      })
      .finally(() => setLoading(false));
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Handler for adding a new category
  const handleAddBatch = () => {
    setFormType('create');
    setOpenForm(true);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <ConfigProvider button={{ className: styles.linearGradientButton }}>
        <AntdButton
          onClick={handleAddBatch}
          type="primary"
          icon={<UserAddOutlined />}
          size="large"
          className="my-2 float-end w-48 text-lg font-medium"
        >
          Add Batch
        </AntdButton>
      </ConfigProvider>

      <ListComponent
        data={data}
        fetchData={fetchData}
        setOpenForm={setOpenForm}
        setSelectedData={setSelectedData}
        setFormType={setFormType}
        loading={loading}
        setOpenView={setOpenView}
      />

      <FormComponent
        openForm={openForm}
        setOpenForm={setOpenForm}
        fetchData={fetchData}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        formType={formType}
      />

      <ViewComponent
        openView={openView}
        setOpenView={setOpenView}
        selectedData={selectedData}
      />
    </Box>
  );
}
