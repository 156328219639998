import React, { useState, useEffect, useMemo } from 'react';

// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  GridItem,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card.js';
import BarChart from 'components/charts/BarChart';

// Ant Design imports
import { Col, Row, Statistic, Button } from 'antd';
import { Skeleton, Typography, Space, Tag } from 'antd';

// Lottie imports
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import UpArrow from '../assests/Lottie/Up.lottie';
import DownArrow from '../assests/Lottie/Down.lottie';
import Charging from '../assests/Lottie/Charging.lottie';
import Online from '../assests/Lottie/Online.lottie';
import Offline from '../assests/Lottie/Offline.lottie';

// Chart imports
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from 'variables/charts';

// Icons
import { MdBarChart } from 'react-icons/md';

// Utilities
import CountUp from 'react-countup';

export default function WeeklyRevenue(props) {
  const { loading, StatusData, ...rest } = props;
  const { Countdown } = Statistic;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue(
    { bg: 'secondaryGray.400' },
    { bg: 'whiteAlpha.50' },
  );
  const bgFocus = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.100' },
  );

  const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Example deadline

  // State for animated value
  const [value, setValue] = useState({
    startValue: 0,
    endValue: 0,
  });
  const [TimeCount, setTimeCount] = useState(0);
  const countdownValue = useMemo(
    () => Date.now() + TimeCount * 1000,
    [TimeCount],
  );

  // Value formatter component
  function ValueFormatter({ startValue, endValue }) {
    return <CountUp start={startValue} end={endValue} />;
  }
  const [PStatusData, setPStatusData] = useState({
    Percentage: 0,
    EstimatedTime: 0,
  });
  useEffect(() => {
    if (PStatusData?.EstimatedTime !== StatusData.EstimatedTime) {
      setTimeCount(StatusData.EstimatedTime);
      console.log(StatusData.EstimatedTime);
      console.log(Date.now(StatusData.EstimatedTime));
    }
    setValue({
      startValue: PStatusData?.Percentage,
      endValue: StatusData?.Percentage,
    });
    setPStatusData({
      Percentage: StatusData?.Percentage,
      EstimatedTime: StatusData?.EstimatedTime,
    });
  }, [StatusData]);

  return (
    <Card align="center" direction="column" w="100%" h="340px" {...rest}>
      {/* Title */}
      <Text
        me="auto"
        color={textColor}
        fontSize="xl"
        fontWeight="700"
        lineHeight="100%"
        marginBottom="0.5rem"
      >
        Status
      </Text>

      {/* Lottie Animation and Actions */}
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <Skeleton.Node
            active
            loading={loading}
            style={{
              height: '200px',
              width: '100%',
              marginBottom: '16px',
              borderRadius: '16px',
            }}
          >
            {/* Lottie Animation */}
            {/* <DotLottieReact src={DownArrow} loop autoplay /> */}
            {/* <DotLottieReact src={UpArrow} loop autoplay /> */}
            <DotLottieReact
              src={Charging}
              loop
              autoplay
              speed={0.4}
              style={{ opacity: 0 }}
            />
            {/* <DotLottieReact src={Online} loop autoplay /> */}
            {/* <DotLottieReact src={Offline} loop autoplay /> */}
            {/* Button to Update Value */}
          </Skeleton.Node>
        ) : (
          <>
            {StatusData?.Image === 'DownArrow' ? (
              <DotLottieReact src={DownArrow} loop autoplay />
            ) : StatusData?.Image === 'UpArrow' ? (
              <DotLottieReact src={UpArrow} loop autoplay />
            ) : StatusData?.Image === 'Charging' ? (
              <DotLottieReact
                src={Charging}
                loop
                autoplay
                speed={0.4}
                style={{ opacity: 0 }}
              />
            ) : StatusData?.Image === 'Online' ? (
              <DotLottieReact src={Online} loop autoplay />
            ) : StatusData?.Image === 'Offline' ? (
              <DotLottieReact src={Offline} loop autoplay />
            ) : (
              ''
            )}
          </>
        )}

        {/* Statistics and Countdown */}
        <Row gutter={16} style={{ width: '100%' }}>
          <Col span={12}>
            <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
              {StatusData?.Image !== 'Online' &&
              StatusData?.Image !== 'Offline' ? (
                <Statistic
                  title="Percentage"
                  value={value.endValue}
                  suffix="%"
                  formatter={() => (
                    <ValueFormatter
                      startValue={value.startValue}
                      endValue={value.endValue}
                    />
                  )}
                />
              ) : (
                <Statistic title="Status" value={`${StatusData?.Status}`} />
              )}
            </Skeleton>
          </Col>
          <Col span={12}>
            <Skeleton loading={loading} active paragraph={{ rows: 1 }}>
              {StatusData?.Image !== 'Online' &&
                StatusData?.Image !== 'Offline' && (
                  <Countdown
                    title="Estimated Time"
                    value={countdownValue}
                    format="mm:ss"
                  />
                )}
            </Skeleton>
          </Col>
        </Row>
      </div>
    </Card>
  );
}
