import React, { useState, useEffect } from 'react';
import { Modal, Descriptions, Image, Tag } from 'antd';
import moment from 'moment';
import { Base_URL } from 'services/DataConfig';

export default function ViewComponent({ openView, setOpenView, selectedData }) {
  const [details, setDetails] = useState([]);

  // Helper function to filter data
  const filterData = (data) => (data ? data : '-');

  // Effect to prepare row details when selectedData changes
  useEffect(() => {
    const rowDetails = [
      {
        key: '1',
        label: 'Name',
        span: 3,
        children: filterData(selectedData?.name),
      },
      {
        key: '2',
        label: 'Description',
        span: 3,
        children: filterData(selectedData?.description),
      },
      {
        key: '3',
        label: 'Status',
        span: 1,
        children: selectedData?.isActive ? 'Active' : 'Inactive',
      },
      {
        key: '4',
        label: 'Created Date',
        span: 2,
        children: filterData(
          moment(selectedData?.created_date).format('MMMM Do YYYY, h:mm:ss a'),
        ),
      },
      {
        key: '5',
        label: 'Features',
        span: 3,
        children: (
          <>
            {selectedData?.features &&
              Object.entries(selectedData.features).map((tag, index) => (
                <Tag color="blue" key={index}>
                  {tag[1]}
                </Tag>
              ))}
          </>
        ),
      },
      {
        key: '6',
        label: 'Gallery',
        span: 3,
        children: (
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Image.PreviewGroup>
              {selectedData?.image1 && (
                <Image width={150} src={`${Base_URL}${selectedData.image1}`} />
              )}
              {selectedData?.image2 && (
                <Image width={150} src={`${Base_URL}${selectedData.image2}`} />
              )}
              {selectedData?.image3 && (
                <Image width={150} src={`${Base_URL}${selectedData.image3}`} />
              )}
            </Image.PreviewGroup>
          </div>
        ),
      },
    ];
    setDetails(rowDetails);
  }, [selectedData]);

  return (
    <Modal
      open={openView}
      footer={null}
      onCancel={() => setOpenView(false)}
      width="600px"
    >
      <Descriptions
        bordered
        size="medium"
        title="Category Details"
        layout="vertical"
        column={3}
      >
        {details.map((item) => (
          <Descriptions.Item key={item.key} label={item.label} span={item.span}>
            {item.children}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Modal>
  );
}
