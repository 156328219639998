import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Input, Flex } from 'antd';
import { useToast } from '@chakra-ui/react';
import adminAccounts from '../../../../api/methods/adminAccounts';
export default function AdminAccountForm({
  openAdminAccountForm,
  setOpenAdminAccountForm,
  fetchAdminAccounts,
  selectedAdminAccount,
  adminAccountFormType,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (adminAccountFormType === 'edit') {
      form.setFieldsValue({
        ...selectedAdminAccount,
      });
    }
  }, [selectedAdminAccount]);

  const [FormSubmiting, setFormSubmiting] = useState(false);

  const onFinish = (values) => {
    setFormSubmiting(true);
    console.log(values);
    if (adminAccountFormType === 'edit') {
      adminAccounts
        .update(selectedAdminAccount.id, values)
        .then((data) => {
          toast({
            title: 'Account Updated Successfully!',
            description:
              'Your admin account is Updated. Start managing with ease.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          form.resetFields();
          setOpenAdminAccountForm(false);
          fetchAdminAccounts();
        })
        .catch((error) => {})
        .finally(() => {
          setFormSubmiting(false);
        });
    }
    if (adminAccountFormType === 'create') {
      adminAccounts
        .create(values)
        .then((data) => {
          toast({
            title: 'Account Created Successfully!',
            description:
              'Your admin account is ready. Start managing with ease.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          form.resetFields();
          setOpenAdminAccountForm(false);
          fetchAdminAccounts();
        })
        .catch((error) => {})
        .finally(() => {
          setFormSubmiting(false);
        });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    toast({
      title: errorInfo.errorFields[0].errors,
      status: 'info',
      duration: 3000,
      isClosable: true,
      max: 3,
    });
  };
  const toast = useToast();
  return (
    <>
      <Modal
        title={`${
          adminAccountFormType === 'edit' ? 'Update' : 'Set up'
        } admin account by completing the form below!`}
        open={openAdminAccountForm}
        footer={null}
        onCancel={() => {
          setOpenAdminAccountForm(false);
        }}
        width={'600px'}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          size={'large'}
          className="mt-6"
        >
          <Flex justify="space-between">
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[{ required: true, message: 'Please enter First Name!' }]}
              style={{ width: '48%' }}
            >
              <Input placeholder="John" />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[{ required: true, message: 'Please enter Last Name!' }]}
              style={{ width: '48%' }}
            >
              <Input placeholder="Jane" />
            </Form.Item>
          </Flex>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please enter Username!' }]}
          >
            <Input placeholder="admin@example.com" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please enter Email!' }]}
          >
            <Input placeholder="admin@example.com" />
          </Form.Item>
          <Form.Item
            name="designation"
            label="Designation"
            rules={[{ required: true, message: 'Please enter Designation!' }]}
          >
            <Input placeholder="Ex: Administrator" />
          </Form.Item>
          <Form.Item
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={FormSubmiting}
              style={{
                width: '200px',
              }}
            >
              {/* {type == 'create' ? 'Submit' : 'Update'} */}
              {adminAccountFormType === 'edit'
                ? 'Update Account'
                : 'Create Account'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
