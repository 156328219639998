import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Button } from '@chakra-ui/react';
import { Label } from '../../src/AceternityComponents/ui/label.tsx';
import { Input } from '../../src/AceternityComponents/ui/input.tsx';
import { cn } from '../../lib/utils.ts';
import {
  readEmail,
  storeToken,
  storeRefreshToken,
} from 'services/localServices.jsx';
import authApis from '../../api/methods/auth.jsx';
import userProduct from '../../api/methods/userProduct.jsx';
import { Col, Row, Statistic, Modal } from 'antd';
const { Countdown } = Statistic;

export default function ValidateOTP({
  openValidateOtpForm,
  setOpenValidateOtpForm,
  serialNumber,
  token,
  FetchUserProducts,
}) {
  const navigate = useNavigate();
  const toast = useToast();

  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [email, setEmail] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  // Create refs for each OTP input
  const inputRefs = useRef([]);

  const handleOtpChange = (element, index) => {
    const value = element.value;

    if (isNaN(value)) return; // Ensure the input is a number

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input if a digit is entered and there is a next input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (event, index) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);

      // Move to the previous input on backspace if there is one
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData('text');

    if (pasteData.length === 6 && /^\d+$/.test(pasteData)) {
      // Check if the paste data is 6 digits
      const newOtp = pasteData.split('');
      setOtp(newOtp);

      // Focus the last input box after pasting
      inputRefs.current[5].focus();
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const otpValue = otp.join('');
    if (otpValue.length !== 6) {
      toast({
        title: 'Incomplete Information',
        description:
          'Make sure to provide your email and enter the full 6-digit OTP.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setButtonLoading(true);

    // Proceed with API call for OTP verification
    console.log('Payload', {
      serial_number: serialNumber,
      token: token,
      otp: otpValue,
    });

    userProduct
      .addVerify({
        serial_number: serialNumber,
        token: token,
        otp: otpValue,
      })
      .then((res) => {
        console.log(res);
        toast({
          title: 'Device Added Successfully',
          description:
            'Your device has been added successfully. You can now manage it from your dashboard.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        // FetchUserProducts();
        resetAndCloseForm();
        navigate('#FetchProducts');
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setButtonLoading(false);
      });
  };
  const [timeLeft, setTimeLeft] = useState(90); // 90 seconds countdown

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [timeLeft]);

  const handleResendOtp = () => {
    if (timeLeft === 0) {
      // Logic to resend OTP
      console.log('Resending OTP...');
      setTimeLeft(90); // Reset the timer
      authApis
        .registerResendOTP({
          email: readEmail(),
        })
        .then((res) => {
          console.log(res);
          toast({
            title: 'OTP Resent Successfully',
            description:
              'A new OTP has been sent to your email. Please check your inbox.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }
  };
  const resetAndCloseForm = () => {
    setOpenValidateOtpForm(false);
    setOtp(new Array(6).fill('')); // Reset OTP state
    // setSelectedData({});
  };
  return (
    <Modal
      title={``}
      open={openValidateOtpForm}
      footer={null}
      onCancel={resetAndCloseForm}
      width="400px"
    >
      <div className="max-w-md w-full z-20 mx-auto rounded-none md:rounded-2xl py-4 shadow-input bg-white dark:bg-black">
        <h2 className="font-bold text-xl text-neutral-800 dark:text-neutral-200">
          Verify Your OTP
        </h2>
        <p className="text-neutral-600 text-sm max-w-sm mt-2 dark:text-neutral-300">
          Enter the 6-digit OTP sent to your email{' '}
          <span className="font-bold">{readEmail()}</span> to complete the
          registration process.
        </p>

        <form className="my-8" onSubmit={handleSubmit}>
          <LabelInputContainer className="mb-4">
            <Label htmlFor="otp">OTP</Label>
            <div className="flex space-x-2">
              {otp.map((_, index) => (
                <Input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="w-12 text-center"
                  value={otp[index]}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  onKeyDown={(e) => handleOtpKeyDown(e, index)}
                  onFocus={(e) => e.target.select()} // Select the input on focus
                  ref={(el) => (inputRefs.current[index] = el)} // Assign ref to each input
                  onPaste={index === 0 ? handlePaste : null} // Only attach the onPaste handler to the first input
                />
              ))}
            </div>
          </LabelInputContainer>
          {/* <div className="h-10 flex justify-between items-center float-end cursor-pointer">
          <p
            className="text-neutral-800 text-[14px] max-w-sm mt-0 mb-2 font-medium  ease-in duration-300 dark:text-neutral-300 hover:underline"
            onClick={(e) => {
              navigate('#ForgotPassword');
            }}
          >
            <Countdown
              title="Countdown"
              value={Date.now() + 90 * 1000}
              onFinish={(e) => {
                console.log(e);
              }}
              format="ss"
            />
            Resend OTP ?
          </p>
        </div> */}
          <div className={`h-10 flex justify-between items-center float-right`}>
            <p
              className={`text-neutral-800 text-[14px] max-w-sm mt-0 mb-2 font-medium ease-in duration-300 dark:text-neutral-300  ${
                timeLeft > 0
                  ? 'text-gray-500'
                  : 'cursor-pointer hover:underline'
              }`}
              onClick={handleResendOtp}
            >
              {timeLeft > 0 ? `Resend OTP in ${timeLeft}s` : 'Resend OTP?'}
            </p>
          </div>
          <Button
            type="submit"
            width="full"
            height="10"
            fontWeight="medium"
            color="white"
            rounded="md"
            bg="gray.800"
            isLoading={buttonLoading}
            sx={{
              _hover: {
                backgroundColor: 'black',
              },
            }}
          >
            Verify OTP &rarr;
          </Button>
        </form>
      </div>
    </Modal>
  );
}

const LabelInputContainer = ({ children, className }) => {
  return (
    <div className={cn('flex flex-col space-y-2 w-full', className)}>
      {children}
    </div>
  );
};
