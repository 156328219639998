import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Button,
  Input,
  Upload,
  Select,
  Switch,
  Typography,
  Flex,
} from 'antd';
import { useToast } from '@chakra-ui/react';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import variant from '../../../../api/methods/variant';
import { Base_URL } from 'services/DataConfig';

export default function FormComponent({
  openForm,
  setOpenForm,
  fetchData,
  selectedData,
  formType,
  dataCategory,
}) {
  // Default specification fields
  const defaultFields = [
    { key: 'Motor Type', value: '' },
    { key: 'Material', value: '' },
  ];

  // State variables
  const [form] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [fields, setFields] = useState(defaultFields);

  const toast = useToast();
  const { Text } = Typography;

  // Load selected data when formType is 'edit'
  useEffect(() => {
    if (formType === 'edit') {
      initializeEditForm();
    } else if (formType === 'create') {
      resetForm();
    }
  }, [formType, selectedData]);

  // Initialize form for editing
  const initializeEditForm = () => {
    form.setFieldsValue({ ...selectedData });
    setFeatureList(selectedData.features || []);
    setFields(selectedData.specifications || []);
    setIsActive(selectedData.isActive);

    const images = [];
    for (let i = 1; i <= 6; i++) {
      if (selectedData[`image${i}`]) {
        images.push({
          uid: `${i}`,
          name: 'Image',
          url: `${Base_URL}${selectedData[`image${i}`]}`,
        });
      }
    }
    setFileList(images);
  };

  // Reset form for creation
  const resetForm = () => {
    form.resetFields();
    setFileList([]);
    setFields([...defaultFields]);
    setFeatureList([]);
  };

  // Helper function to handle file uploads
  const handleFileOrObject = async (file, formData, keyName) => {
    if (file instanceof File) {
      formData.append(keyName, file);
    } else if (typeof file === 'object') {
      try {
        const response = await fetch(file.url);
        if (!response.ok) throw new Error('Failed to fetch image');

        const imageBlob = await response.blob();
        formData.append(keyName, imageBlob, `${keyName}.jpg`);
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }
  };

  // Upload props for handling image uploads
  const uploadProps = {
    onRemove: (file) => {
      setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
    },
    beforeUpload: (file) => {
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        toast({
          title: 'You can only upload JPG/PNG files!',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
      setFileList((prevList) => [...prevList, file]);
      return false;
    },
    fileList,
  };

  // Handle form submission
  const onFinish = async (values) => {
    if (validateForm(values)) {
      submitForm(values);
    }
  };

  // Validate form before submission
  const validateForm = (values) => {
    if (fileList.length === 0) {
      showToast('Please upload at least one image!', 'info');
      return false;
    }
    if (featureList.length === 0) {
      showToast('Please add at least one feature!', 'info');
      return false;
    }
    if (values.MRP < values.selling_price) {
      showToast(
        'Sale Price should be less than or equal to MRP Price!',
        'info',
      );
      return false;
    }
    return true;
  };

  // Show toast messages
  const showToast = (message, status) => {
    toast({
      title: message,
      status,
      duration: 3000,
      isClosable: true,
    });
  };

  // Submit form data
  const submitForm = async (values) => {
    setFormSubmitting(true);
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('model_number', values.model_number);
    formData.append('MRP', values.MRP);
    formData.append('selling_price', values.selling_price);
    formData.append('category', values.category);
    formData.append('is_active', isActive);
    formData.append('features', JSON.stringify(featureList));
    formData.append('specifications', JSON.stringify(fields));

    for (let i = 0; i < 6; i++) {
      if (i < fileList.length) {
        await handleFileOrObject(fileList[i], formData, `image${i + 1}`);
      } else {
        formData.append(`image${i + 1}`, '');
      }
    }
    const action = formType === 'edit' ? variant.update : variant.create;
    const successMessage =
      formType === 'edit'
        ? 'Variant Updated Successfully!'
        : 'Variant Created Successfully!';

    action(formData, selectedData?.id)
      .then(() => {
        showToast(successMessage, 'success');
        resetForm();
        setOpenForm(false);
        fetchData(values.category);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => setFormSubmitting(false));
  };

  // Handle form submission failure
  const onFinishFailed = (errorInfo) => {
    showToast(
      errorInfo.errorFields[0]?.errors[0] || 'Form submission failed!',
      'info',
    );
  };

  // Handle input change for specifications
  const handleInputChange = (index, name, value) => {
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    setFields(updatedFields);
  };

  // Add new specification field
  const addField = () => {
    setFields([...fields, { key: '', value: '' }]);
  };

  // Remove specification field
  const removeField = (index) => {
    setFields((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Modal
      title={`${formType === 'edit' ? 'Update' : 'Create'} Variant`}
      open={openForm}
      footer={null}
      onCancel={() => {
        resetForm();
        setOpenForm(false);
      }}
      width="600px"
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        size="large"
      >
        <Form.Item
          name="name"
          label="Name of the Variant"
          rules={[{ required: true, message: 'Please enter the Name!' }]}
        >
          <Input placeholder="Enter Variant name" />
        </Form.Item>
        <Form.Item
          name="model_number"
          label="Model Number"
          rules={[
            { required: true, message: 'Please enter the Model Number!' },
          ]}
        >
          <Input placeholder="Enter Model Number" />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: 'Please select the Category!' }]}
        >
          <Select
            placeholder="Select Category"
            options={dataCategory}
            onChange={(value) => console.log(value)}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the Description!' }]}
        >
          <Input.TextArea placeholder="Enter Variant description" rows={3} />
        </Form.Item>
        <Form.Item name="features" label="Features">
          <Select
            mode="tags"
            style={{ width: '100%' }}
            value={featureList}
            onChange={setFeatureList}
            placeholder="Add features separated by commas"
            tokenSeparators={[',']}
          />
        </Form.Item>
        <Flex justify="space-between">
          <Form.Item
            name="MRP"
            label="MRP Price"
            rules={[{ required: true, message: 'Please enter the MRP Price!' }]}
            style={{ width: '49%' }}
          >
            <Input type="number" placeholder="Enter MRP Price!" />
          </Form.Item>
          <Form.Item
            name="selling_price"
            label="Sale Price"
            rules={[
              { required: true, message: 'Please enter the Sale Price!' },
            ]}
            style={{ width: '49%' }}
          >
            <Input type="number" placeholder="Enter Sale Price!" />
          </Form.Item>
        </Flex>
        {formType === 'edit' && (
          <Form.Item name="is_active" label="Is Active">
            <Switch checked={isActive} onChange={setIsActive} />
          </Form.Item>
        )}
        <Flex justify="space-between" style={{ marginBottom: '20px' }}>
          <Text>Specifications</Text>
          <Button type="primary" size="small" onClick={addField}>
            Add Field
          </Button>
        </Flex>
        {fields.map((field, index) => (
          <Form.Item key={index}>
            <Flex justify="space-between">
              <Input
                placeholder="Key"
                value={field.key}
                onChange={(e) =>
                  handleInputChange(index, 'key', e.target.value)
                }
                style={{ width: '44%' }}
                readOnly={index < defaultFields.length}
                required
              />
              <Input
                placeholder="Value"
                value={field.value}
                onChange={(e) =>
                  handleInputChange(index, 'value', e.target.value)
                }
                style={{ width: '44%' }}
                required
              />
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={() => removeField(index)}
                disabled={index < defaultFields.length}
              />
            </Flex>
          </Form.Item>
        ))}
        <Form.Item name="FilePath" label="Upload Images">
          <ImgCrop rotationSlider aspect={16 / 9}>
            <Upload listType="picture-card" {...uploadProps}>
              {fileList.length < 6 && <PlusOutlined />}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={formSubmitting}
            style={{ width: '200px' }}
          >
            {formType === 'edit' ? 'Update Variant' : 'Create Variant'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
