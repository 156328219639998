import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  useToast,
  Image,
} from '@chakra-ui/react';

// Custom components
import Banner from 'views/Pages/HomePage/components/Banner';
import ListCard from 'components/variantPage/ListCard.js';
import ListCardLoading from 'components/variantPage/ListCardLoading.js';
import Card from 'components/card/Card.js';

// Assets
import NoDevices from 'assets/img/homePage/NoDevice.svg';

// API
import userProduct from '../../../api/methods/userProduct';
import category from '../../../api/methods/category';
import variant from '../../../api/methods/variant';
import { Base_URL } from 'services/DataConfig';

export default function Marketplace() {
  // Chakra hooks
  const toast = useToast();
  const navigate = useNavigate();

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.500', 'white');

  // States
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productsAvailable, setProductsAvailable] = useState(true);

  // Fetch user products
  useEffect(() => {
    category
      .list()
      .then((response) => {
        console.log(response);
        if (response.data.length === 0) {
          toast({
            title: 'No Categorys Found',
            description:
              'It seems like there are no devices linked to your account. Please add a device to get started.',
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          setProductsAvailable(false);
        } else {
          setProductsAvailable(true);
          setProducts(response.data);
        }
        console.log(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
      {/* Main Content */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: 'repeat(3, 1fr)', '2xl': '1fr 0.46fr' }}
        gap={{ base: '20px', xl: '20px' }}
        display={{ base: 'block', xl: 'grid' }}
      >
        {/* Left Column */}
        <Flex
          flexDirection="column"
          gridArea={{ xl: '1 / 1 / 2 / 4', '2xl': '1 / 1 / 2 / 2' }}
        >
          {/* <Banner /> */}
          <Flex direction="column">
            {/* Section Header */}
            <Flex
              mt="45px"
              mb="20px"
              justifyContent="space-between"
              direction={{ base: 'column', md: 'row' }}
              align={{ base: 'start', md: 'center' }}
            >
              <Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
                Category's
              </Text>
            </Flex>

            {/* No Devices Found */}
            {!productsAvailable && (
              <>
                <Image
                  src={NoDevices}
                  w={{ base: '100%', '3xl': '100%' }}
                  h={{ base: '100%', lg: '300px' }}
                  borderRadius="20px"
                />
                <div className="flex justify-center items-center mt-5 w-full">
                  <button className="w-48 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                    Add Device
                  </button>
                </div>
              </>
            )}

            {/* Devices Available */}
            {loading && (
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="20px">
                <ListCardLoading />
                <ListCardLoading />
                <ListCardLoading />
                <ListCardLoading />
              </SimpleGrid>
            )}

            {/* Devices Available */}
            {productsAvailable && (
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap="20px">
                {products.map((data) => (
                  <ListCard
                    key={data?.id}
                    name={`${data?.name}`}
                    name2={`${
                      data?.alias_name ? data?.batch?.variant?.name : ''
                    }`}
                    name3={`${data?.description}`}
                    download="#"
                    image={`${Base_URL}${data?.image1}`}
                    AllData={data}
                  />
                ))}
              </SimpleGrid>
            )}
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
}
